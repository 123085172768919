<div class='full-input' (click)="editable ? select() : null">
  <label class="label-input_l">{{ title }}</label>

  @if(inputmode){
  <input class="label-input_i" #input [id]="id+'-input'" [value]="value" [type]="type" (focus)="isFocused = true"
    (blur)="isFocused = false; inputmode = false" (input)="onChange.emit(input.value)"
    [disabled]="!editable || !hasCaseManagementAccess" />
  } @else {
  <input class="label-input_i" #displayRef [id]="id" [value]="display" [type]="type"
    [disabled]="!editable || !hasCaseManagementAccess" />
  }
</div>