import { AfterViewInit, Component, EventEmitter, Input, Output, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { RandomId } from 'src/app/util/RandomId';



@Component({
  selector: 'app-label-input',
  templateUrl: './label-input.component.html',
  styleUrls: ['./label-input.component.scss'],
  standalone: true
})
export class LabelInputComponent implements OnInit, AfterViewInit {
  @ViewChild('input') input!: TemplateRef<HTMLInputElement>;

  @Input() title: string = '';
  @Input() name: string = '';
  @Input() editable: boolean = false;
  @Input() type: string = '';

  @Input() value: number | null | string = null;
  @Input() display!: string | null;

  hasCaseManagementAccess: boolean = false;


  constructor(private rights: UserRightsProviderService) {
    this.hasCaseManagementAccess = this.rights.checkCaseManagementAccess()
  }
  isFocused: boolean = false;
  inputmode: boolean = false;

  @Output() onChange = new EventEmitter<any>();

  id = RandomId();

  ngOnInit(): void {
    if (!this.display) {
      this.display = String(this.value);
    }
  }

  number = Number;

  ngAfterViewInit(): void {

  }

  select() {
    this.inputmode = true;
    setTimeout(() => {
      this.input.elementRef.nativeElement.focus();
    }, 1)

  }
}
