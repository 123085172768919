<div class="desktop">
  <div class="flex-row space-between component-structure head-spacer">
    <div class="flex-col">
      <app-title [value]="caseData.getOrigin().Number + ' - ' + caseData.getOrigin().Title"></app-title>
      <app-heading [value]="caseHeading"></app-heading>
    </div>
    <div class="flex-row toggle-expand">
      <button *ngIf="caseData.isChanged && caseAccesLevel !== 'LOOK'" class="case-save-button" id="submit"
        igxButton="raised" igxButtonColor="white" [style.background]="'#AEC965'" igxRipple="white"
        (click)="onSave()">LAGRE</button>
      <ng-container *ngIf="expanded === false; then more; else less"></ng-container>
    </div>
  </div>

  <div [ngStyle]="{'display': expanded && screen.width > 550 ? 'flex' : 'none'}" class="selected-case-grid">
    <app-selected-case #selectedCase class="component-structure" [title]="'Saksinformasjon'"
      [data]="caseData"></app-selected-case>
    <app-key-fin-figures class="keyfin-figs" [data]="caseData" [title]="'Nøkkeltall for sak'"></app-key-fin-figures>
  </div>
</div>
<div class="tablet">
  <igx-accordion>
    <igx-expansion-panel #panel [collapsed]="!expanded">
      <igx-expansion-panel-header>
        <div class="tablet">
          <div class="flex-row space-between component-structure head-spacer">
            <div class="flex-col">
              <app-title [value]="caseData.getOrigin().Title"></app-title>
              <app-heading [value]="caseHeading"></app-heading>
            </div>
            <div class="flex-row toggle-expand">
              <button *ngIf="caseData.isChanged" class="case-save-button" id="submit" igxButton="raised"
                igxButtonColor="white" [style.background]="'#AEC965'" igxRipple="white"
                (click)="onSave();  $event.stopPropagation()">LAGRE</button>
            </div>
          </div>
        </div>
      </igx-expansion-panel-header>
      <igx-expansion-panel-body>
        <ng-container
          *ngIf="screen.width < 800; then verticalCaseDetailsTemplate; else horizontalCaseDetailsTemplate"></ng-container>
      </igx-expansion-panel-body>
    </igx-expansion-panel>
  </igx-accordion>
</div>
<div class="mobile">
  <igx-accordion #accordion [singleBranchExpand]="false">
    <igx-expansion-panel [collapsed]="true" class="component-structure margin-bottom">
      <igx-expansion-panel-header>
        <igx-expansion-panel-title>{{ caseData.getTitle() }}</igx-expansion-panel-title>
        <div class="flex-row toggle-expand">
          <button *ngIf="caseData.isChanged" class="case-save-button" id="submit" igxButton="raised"
            igxButtonColor="white" [style.background]="'#AEC965'" igxRipple="white" (click)="onSave()">LAGRE</button>
        </div>
      </igx-expansion-panel-header>
      <igx-expansion-panel-body>
        <div class="flex-col spacer mobile-header">
          <strong>Klient: {{ caseClient }}</strong>
          <strong>Saksansvarlig: {{ caseResponsible }}</strong>
        </div>
      </igx-expansion-panel-body>
    </igx-expansion-panel>
    <igx-expansion-panel [collapsed]="true" class="component-structure  margin-bottom">
      <igx-expansion-panel-header>
        <igx-expansion-panel-title>Saksinformasjon</igx-expansion-panel-title>
      </igx-expansion-panel-header>
      <igx-expansion-panel-body>
        <app-selected-case #selectedCase [data]="caseData" class="component-structure m-top-s"></app-selected-case>
      </igx-expansion-panel-body>
    </igx-expansion-panel>
    <igx-expansion-panel [collapsed]="true" class="component-structure margin-bottom">
      <igx-expansion-panel-header>
        <igx-expansion-panel-title>Nøkkeltall for sak</igx-expansion-panel-title>
      </igx-expansion-panel-header>
      <igx-expansion-panel-body>
        <app-key-fin-figures [data]="caseData"></app-key-fin-figures>
      </igx-expansion-panel-body>
    </igx-expansion-panel>
  </igx-accordion>
</div>


<igx-tabs id="selected-case-igx-tabs" class="igx-box-shadow tabs" (selectedIndexChanging)="onTabChange($event)">
  <igx-tab-item>
    <igx-tab-header (click)="collapsePanel(); panel.collapse()">
      <span igxTabHeaderLabel>Roller</span>
      <i class="material-icons icon-default">supervisor_account</i>
    </igx-tab-header>
    <igx-tab-content>
      <app-case-roles #caseRolesRef [data]="caseData" (onSave)="caseRoleChaged($event)"
        (mandatoryRolesCheck)="onMandatoryRolesCheck($event)"
        (changeOriginCheck)="onChangeOriginCheck($event)"></app-case-roles>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [disabled]="caseData.getIsNew() || hourRegistrationRights === 'NONE' || isCaseClosed()"
    [accessKey]="'belastning'">
    <igx-tab-header (click)="collapsePanel(); panel.collapse()">
      <span igxTabHeaderLabel>Belastning</span>
      <i class="material-icons icon-default">payments</i>
    </igx-tab-header>
    <igx-tab-content>
      <igx-tabs style="height: 100%;">
        <igx-tab-item>
          <igx-tab-header>
            <igx-tab-header-label>Timer</igx-tab-header-label>
          </igx-tab-header>
          <igx-tab-content>
            <!-- <app-hours-registration (addNewRoleHours)="handleAddRoleEventForHours($event)"
              [data]="caseData"></app-hours-registration> -->
            <app-hours-registration (notifyParent)="changeFromTimeRegistration($event)"
              [data]="caseData"></app-hours-registration>
          </igx-tab-content>
        </igx-tab-item>
        <igx-tab-item>
          <igx-tab-header>
            <igx-tab-header-label>Omkostninger</igx-tab-header-label>
          </igx-tab-header>
          <igx-tab-content>
            <!-- <app-costs (addNewRoleCosts)="handleAddRoleEventForCosts($event)" [data]="caseData"></app-costs> -->
            <app-costs (notifyParent)="changeFromTimeRegistration($event)" [data]="caseData"></app-costs>
          </igx-tab-content>
        </igx-tab-item>
      </igx-tabs>
      <!-- <app-charging [data]="caseData"></app-charging> -->
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item [disabled]="caseData.getIsNew() || caseDocumentRights === 'NONE'">
    <igx-tab-header (click)="collapsePanel(); panel.collapse()">
      <span igxTabHeaderLabel>Dokumenter</span>
      <i class="material-icons icon-default">folder_open</i>
    </igx-tab-header>
    <igx-tab-content>
      <app-document-grid [case]="caseData.getCase()"></app-document-grid>
    </igx-tab-content>
  </igx-tab-item>
</igx-tabs>



<ng-template #less>
  <div class="flex-row icon-default toggle-expand" (click)="changeExpand()">
    <app-heading [value]="'Skjul saksinformasjon'"></app-heading>
    <i class="material-icons margin-left">arrow_circle_up</i>
  </div>
</ng-template>

<ng-template #more>
  <div class="flex-row icon-default toggle-expand" (click)="changeExpand()">
    <app-heading [value]="'Vis saksinformasjon'"></app-heading>
    <i class="material-icons margin-left">arrow_circle_down</i>
  </div>
</ng-template>


<ng-template #verticalCaseDetailsTemplate>
  <igx-tabs class="igx-box-shadow" [tabAlignment]="'justify'">
    <igx-tab-item [selected]="true">
      <igx-tab-header>
        <span igxTabHeaderLabel>Saksinformasjon</span>
      </igx-tab-header>
      <igx-tab-content>
        <app-selected-case #selectedCase [data]="caseData"></app-selected-case>
      </igx-tab-content>
    </igx-tab-item>
    <igx-tab-item>
      <igx-tab-header>
        <span igxTabHeaderLabel>Nøkkeltall</span>
      </igx-tab-header>
      <igx-tab-content>
        <app-key-fin-figures [data]="caseData"></app-key-fin-figures>
      </igx-tab-content>
    </igx-tab-item>
  </igx-tabs>
</ng-template>

<ng-template #horizontalCaseDetailsTemplate>
  <div class="case_details-tablet-grid">
    <app-selected-case #selectedCase [data]="caseData" [title]="'Saksinformasjon'"></app-selected-case>
    <app-key-fin-figures [data]="caseData" [title]="'Nøkkeltall for sak'"></app-key-fin-figures>
  </div>
</ng-template>