export function DateDisplay(isoString: string): string {
    if(isoString === "") return ''
    const locale = navigator.language;
    const date = new Date(isoString);
    //switch for future cases
    switch (locale) {
        case "nb-NO":
            // @ts-ignore
            return date.toLocaleDateString('en-GB').replaceAll('/','.');
        default:
            return date.toLocaleDateString();
    }
}