@if( deviceType !== 'Mobile'){
<div class="new-doc-container">

  <div class="template-container">
    <app-heading>Maler:</app-heading>
    <igx-grid class="m-top-s" igxPreventDocumentScroll #docTemplateGrid [data]="documentTemplates"
      [displayDensity]="deviceService.gridDensity" [autoGenerate]="false" [cellSelection]="'none'"
      [rowSelection]="'single'" [hideRowSelectors]="true" (rowSelectionChanging)="templateSelected($event)"
      [allowFiltering]="true" sortable="true" height="310px" width="400px">
      <igx-column field="Name" header="Navn" [sortable]="true" [dataType]="'string'"
        [filterCellTemplate]="docTemplateFilterTemplate"></igx-column>
      <igx-column width="27%" field="DocumentTypeName" header="Maltype" [sortable]="true" [dataType]="'string'"
        [filterCellTemplate]="docTemplateFilterTemplate"></igx-column>
    </igx-grid>
    <igx-hint style="margin: 1rem; cursor: help;">Enkelte maler kan være utilgjengelig hvis disse har et eldre
      filformat</igx-hint>
  </div>

  <div class="doc-info-container">
    <app-heading>Dokumentinformasjon:</app-heading>
    <div class="doc-info-wrapper m-top-s">
      <!-- <div class="document-info flex-col"> -->

      <igx-input-group class="div1" displayDensity="compact" type="border">
        <input igxInput name="title" type="text" [(ngModel)]="title" />
        <label igxLabel for="title">Tittel:</label>
      </igx-input-group>

      <div class="div2 simple-select-wrapper">
        <label #authorLabel igxLabel class="simple-select-label simple-select-label--selected">Forfatter:</label>
        <igx-simple-combo [data]="users" displayDensity="compact" [type]="'border'"
          (opening)="combo.comboOpening(authorLabel)" (closing)="combo.comboClosing(authorLabel, author)"
          [(ngModel)]="author" (ngModelChange)="onAuthorChange($event)" [displayKey]="'ContactName'"
          [valueKey]="'ContactId'">
        </igx-simple-combo>
      </div>

      <igx-input-group class="div3" displayDensity="compact" type="border">
        <input style="pointer-events: none;" igxInput name="date" [value]="viewDate" readonly />
        <label igxLabel for="date">Dato:</label>
      </igx-input-group>

      <igx-input-group class="div4" displayDensity="compact" type="border">
        <textarea igxInput name="Description" autocomplete="off" type="text" rows="4"
          style="resize: none;margin-bottom: 18px;" [(ngModel)]="description"></textarea>
        <label igxLabel for="Description">Beskrivelse</label>
      </igx-input-group>

      <div class=" div5 simple-select-wrapper">
        <label #categoryLabel igxLabel class="simple-select-label simple-select-label--selected">Kategori:</label>
        <igx-simple-combo [data]="documentCategories" displayDensity="compact" [type]="'border'"
          (opening)="combo.comboOpening(categoryLabel)" (closing)="combo.comboClosing(categoryLabel, categoryId)"
          (selectionChanging)="categoryChange($event)" [displayKey]="'Name'" [valueKey]="'Id'" [(ngModel)]="categoryId">
        </igx-simple-combo>
      </div>

      <div class="div6 simple-select-wrapper phone-select">
        <label #subCategoryLabel igxLabel class="simple-select-label simple-select-label--selected">
          {{!(documentSubCategories.length > 0) ? 'U.Kategori:' : 'Velg kategori:' }}</label>
        <igx-simple-combo #subcategories [data]="documentSubCategories" displayDensity="compact"
          (opening)="combo.comboOpening(subCategoryLabel)"
          (closing)="combo.comboClosing(subCategoryLabel, subCategoryId)" [displayKey]="'Name'" [valueKey]="'Id'"
          [(ngModel)]="subCategoryId" [type]="'border'" [disabled]="!(documentSubCategories.length > 0)">
        </igx-simple-combo>
      </div>
      <div class="div7 btn-wrapper">
        <ng-container *ngIf="canOpenDocument; then openActive; else openDisabled"></ng-container>
      </div>
    </div>
  </div>

  <div class="roles-container">
    <app-heading class="header">Roller:</app-heading>
    <div class="grid-button-row m-top-s">
      <app-button-row-button [icon]="'person_add'" [label]="'Hovedadressat'" [enabled]="hasAddressee"
        (click)="setAddressee('Hovedadressat')"></app-button-row-button>
      <app-button-row-button [icon]="'priority_high'" [label]="'Attention'" [enabled]="hasAttention"
        (click)="setAddressee('Attention')"></app-button-row-button>
      <app-button-row-button [icon]="'group_add'" [label]="'Kopi'" [enabled]="hasCopy" (click)="setAddressee('Kopi')">
      </app-button-row-button>
      <app-button-row-button [icon]="'undo'" [label]="'Tilbakestill'" (click)="resetAdressees()">
      </app-button-row-button>
    </div>
    <igx-grid igxPreventDocumentScroll #caseRoleGrid [data]="documentRecipients"
      [displayDensity]="deviceService.gridDensity" [autoGenerate]="false" [cellSelection]="'none'"
      [rowSelection]="'single'" [hideRowSelectors]="true" (rowSelectionChanging)="recipientSelected($event)"
      [allowFiltering]="true" sortable="true" height="310px">
      <igx-column field="RoleTypeName" header="Rolle" [sortable]="true" [dataType]="'string'"
        [filterCellTemplate]="caseRoleFilterTemplate"></igx-column>
      <igx-column field="ContactName" header="Kontakt" [sortable]="true" [dataType]="'string'"
        [filterCellTemplate]="caseRoleFilterTemplate"></igx-column>
      <igx-column field="$Type" header="Type" [sortable]="true" [dataType]="'string'"
        [filterCellTemplate]="caseRoleFilterTemplate"></igx-column>
    </igx-grid>
  </div>

  <ng-template #caseRoleFilterTemplate igxFilterCellTemplate let-column="column">
    <grid-filter-input [grid]="caseRoleGrid" [column]="column"></grid-filter-input>
  </ng-template>
  <ng-template #docTemplateFilterTemplate igxFilterCellTemplate let-column="column">
    <grid-filter-input [grid]="docTemplateGrid" [column]="column"></grid-filter-input>
  </ng-template>

  <ng-template #openActive>
    <div class="flex-col justify-end btn-margin-top">
      <button igxButton="raised" id="submit" igxButtonColor="white" [style.background]="'#AEC965'" igxRipple="white"
        (click)="openDocument()">Åpne</button>
    </div>
  </ng-template>
  <ng-template #openDisabled>
    <div class="flex-col justify-end btn-margin-top">
      <button igxButton="raised" [disabled]="'true'">Åpne</button>
    </div>
  </ng-template>
</div>

} @else {
<div class="not-supported">
  <span class="material-symbols-outlined not-supported-icon">
    security_update_warning
  </span>
  <p>Denne funksjonaliteten er ikke tilgjengelig på mobil.</p>
</div>
}