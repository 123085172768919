import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { CellType, ConnectedPositioningStrategy, HorizontalAlignment, IGridCellEventArgs, IGridEditEventArgs, IgxDialogComponent, IgxGridCell, IgxGridComponent, IgxOverlayOutletDirective, NoOpScrollStrategy, RowType, VerticalAlignment, IgxToggleActionDirective, IgxDropDownItemNavigationDirective, IgxDropDownComponent, IgxCardComponent, IgxCardContentDirective, IgxTooltipTargetDirective, IgxTooltipDirective, IgxButtonDirective, IgxRippleDirective, IgxDialogTitleDirective, IgxIconComponent, IgxColumnComponent, IgxCellHeaderTemplateDirective, IgxCellTemplateDirective, IgxGridFooterComponent, IgxFilterCellTemplateDirective, IgxDialogModule } from '@infragistics/igniteui-angular';
import FileManager from 'src/app/classes/FileManager';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { DateDisplay } from 'src/app/util/DateDisplay';
import { fileIcon } from 'src/app/util/FileIcons';
import { ActivityLogService } from 'src/app/components/UI/activity-log-overlay/activity-log.service';
import { DocumentCheckoutService, WindowWrapper } from 'src/app/components/UI/document-checkout/document-checkout.service';
import { applicationURL } from 'src/app/util/ApplicationURLs';
import { newDoc } from 'src/app/util/TEMP_newDOC';
import { InformationOverlayService } from 'src/app/components/UI/info-overlay/information-overlay.service';
import { getFileExt } from 'src/app/util/FileTypeUtil';
import { AccountingDocumentTypes, DocumentTypeEnum } from 'src/app/util/DocumentTypesUtil';
import { Router } from '@angular/router';
import { DatalexClient, ICaseBE, IDocumentBE, IDocumentCategoryBE, IDocumentLimitedBE, IDocumentSharePointExtendedBE, IDocumentSubCategoryBE, IFileBE } from '@datalex-software-as/datalex-client';
import { IGridSelectChangeEvent } from 'src/app/components/UI/grid-select/grid-select.component';
import { Subscription, firstValueFrom } from 'rxjs';
import { GridMethodsService } from 'src/app/services/grid-methods.service';
import { DatalexMsalService } from 'src/app/services/datalex-msal.service';
import { ContextMenuComponent, CtxItem } from 'src/app/components/UI/context-menu/context-menu.component';
import { DeviceBreakpoints, ScreenSizeService } from 'src/app/services/screen-size.service';
import { EventEmitterService, IForcedEventTypeEnum, IUpdateDocumentGridRow } from 'src/app/services/event-emitter.service';
import newActivity, { Activity } from 'src/app/classes/Activity/Actvity';
import CheckInErrorMessage from 'src/app/util/CheckInErrorMessage';
import { DeviceService } from 'src/app/services/device.service';
import { GridFilterInputComponent } from '../../../../UI/grid-filter-input/grid-filter-input.component';
import { ContextMenuComponent as ContextMenuComponent_1 } from '../../../../UI/context-menu/context-menu.component';
import { ClearGridFiltersDirective } from '../../../../../directives/clear-grid-filters.directive';
import { GridCountDirective } from '../../../../../directives/grid-count.directive';
import { DocumentFromTemplateComponent } from '../document-from-template/document-from-template.component';
import { DocumentCategoryEditorComponent } from '../document-category-editor/document-category-editor.component';
import { DocumentDetailEditorComponent } from '../document-detail-editor/document-detail-editor.component';
import { NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ButtonRowButtonComponent } from '../../../../UI/button-row-button/button-row-button.component';
import { DndDirective } from '../../../../../directives/dnd.directive';
import { SystemDialogEnum } from 'src/app/util/SystemDialogEnum';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { UserAreaEnum, UserRightTypeEnum } from 'src/app/util/UserRightUtil';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { GridDownloadProgressDirective } from 'src/app/directives/grid-download-progress.directive';
import { DocumentSearchDirective } from 'src/app/directives/document-search.directive';
import { DocumentUrlService } from 'src/app/services/document-url.service';

export interface IDocumentLimitedGrid extends IDocumentLimitedBE {
  Icon: string;
  ChangedDate: string;
  CreatedDate: string;
  DocNr: string;
  SubCategorys: IDocumentSubCategoryBE[];
  WebUrl?: string
}




@Component({
  selector: 'app-document-grid',
  templateUrl: './document-grid.component.html',
  styleUrls: ['./document-grid.component.scss'],
  standalone: true,
  imports: [DndDirective, GridDownloadProgressDirective, IgxDialogModule, ButtonRowButtonComponent, IgxToggleActionDirective, IgxDropDownItemNavigationDirective, IgxOverlayOutletDirective, NgIf, IgxDropDownComponent, IgxCardComponent, IgxCardContentDirective, IgxTooltipTargetDirective, IgxTooltipDirective, IgxButtonDirective, IgxRippleDirective, NgStyle, NgTemplateOutlet, IgxDialogComponent, IgxDialogTitleDirective, IgxIconComponent, DocumentDetailEditorComponent, DocumentCategoryEditorComponent, DocumentFromTemplateComponent, IgxGridComponent, GridCountDirective, IgxColumnComponent, IgxCellHeaderTemplateDirective, ClearGridFiltersDirective, IgxCellTemplateDirective, IgxGridFooterComponent, ContextMenuComponent_1, IgxFilterCellTemplateDirective, GridFilterInputComponent, DocumentSearchDirective]
})

export class DocumentGridComponent implements OnInit, AfterViewInit, OnDestroy {

  private router = inject(Router);
  private eventEmitter = inject(EventEmitterService);
  private info = inject(InformationOverlayService);
  private dcs = inject(DocumentCheckoutService);
  private als = inject(ActivityLogService);
  private cdr = inject(ChangeDetectorRef);
  public userRights = inject(UserRightsProviderService)
  screenSize = inject(ScreenSizeService);
  dlxClient = inject(DatalexClient);
  gms = inject(GridMethodsService);
  sys = inject(SystemCacheService);
  dlxMsal = inject(DatalexMsalService);
  public deviceService = inject(DeviceService);
  private docUrl = inject(DocumentUrlService);

  subscriptions!: Subscription
  showClearFiltersButton = false;

  isLoggedInSharepoint = false;
  rowId: any;
  window = window
  windowWidth = window.innerWidth
  isDesktopDevice!: boolean;

  showDetailModal: boolean = false;
  showCategoryModal: boolean = false;
  showNewDocumentFromTemplateModal: boolean = false;


  emptyGridMessage = "Laster inn dokumenter";
  isLoading: boolean = false;

  pdfHREF: string = '';
  isSafari: boolean = false;
  
  @Output() selection: EventEmitter<IDocumentLimitedGrid> = new EventEmitter();

  @ViewChild('grid1', { read: IgxGridComponent, static: true }) public grid1!: IgxGridComponent;
  @ViewChild('DetailModal', { read: IgxDialogComponent }) public DetailModal!: IgxDialogComponent;
  @ViewChild('CategoryModal', { static: true }) public CategoryModal!: IgxDialogComponent;
  @ViewChild('NewDocumentFromTemplateModal', { static: true }) public NewDocumentFromTemplateModal!: IgxDialogComponent;
  @ViewChild('gridContextMenu') public gridContextMenu!: ContextMenuComponent;

  gotDocumentFullPermissions: boolean = false;
  gotDocumentReadPermissions: boolean = false;
  isWindowsDesktop!: boolean;

  public ngOnInit() {


    this.isDesktopDevice = this.deviceService.getDeviceType() === 'Desktop';
    this.isWindowsDesktop = this.deviceService.isWindowsDesktop();

    this.isSafari = this.deviceService.getBrowserType() === "Safari";


    this.setSubscriptions();
    this.azAccessTokenLookup();
    let pathArray = window.location.pathname.split('/')
    let id = pathArray[pathArray.length - 1]

    try {
      this.loadDocuments(id),
        this.loadDocumentCategories()
      this.gotDocumentFullPermissions = this.checkDocumentFullRights();
      this.gotDocumentReadPermissions = this.checkDocumentReadRights();
    } catch (error) {
      this.sys.isReady.subscribe({
        next: () => {
          this.loadDocuments(id),
            this.loadDocumentCategories()
          this.gotDocumentFullPermissions = this.checkDocumentFullRights();
          this.gotDocumentReadPermissions = this.checkDocumentReadRights();
        }
      })
    }
  }

  updateClearFiltersButtonVisibility() {
    this.grid1.filteringExpressionsTreeChange.subscribe(() => {
      const totalFilters = this.grid1.filteringExpressionsTree.filteringOperands.length;
      this.showClearFiltersButton = totalFilters > 1;
    });
  }


  ngAfterViewInit() {
    this.azRedirectUri = this.router.url;
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setSubscriptions() {
    this.subscriptions = new Subscription();

    // this.subscriptions.add(this.eventEmitter.getForceUpdateEvent().subscribe((event) => {
    //   if (event.type === IForcedEventTypeEnum.DocumentsUpdated) {
    //     this.loadDocuments();
    //   }
    // }));

    this.subscriptions.add(this.eventEmitter.getForceUpdateEvent().subscribe((event) => {

      switch (event.type) {
        case IForcedEventTypeEnum.DocumentsUpdated:
          this.updateDocumentGridRow({ document: event.document, rowId: event.rowId });
          break
        case IForcedEventTypeEnum.DocumentCheckedIn:
          this.updateGridAfterCheckedIn(event.id);
          break;
        default:
          break;

      }

    }));


    this.subscriptions.add(this.screenSize.width$.subscribe(width => {
      this.isMobile = width < DeviceBreakpoints.MOBILE_BREAKPOINT;
      this.isTablet = width > DeviceBreakpoints.MOBILE_BREAKPOINT && width < DeviceBreakpoints.TABLET_BREAKPOINT;
      this.isDesktop = width > DeviceBreakpoints.TABLET_BREAKPOINT;
      this.windowWidth = width;
    }));


    this.subscriptions.add(this.dlxMsal.isLoggedInToSharePoint$.subscribe({
      next: value => {
        if (value === null) return
        this.isLoggedInSharepoint = value.isLoggedIn;
        if (value.firstEmit) {
          this.loadDocuments();
        }
      }
    }
    ));
  }

  private updateGridAfterCheckedIn(id: string): void {
    const index = this.filteredDatasource.findIndex(doc => doc.Id === id);
    if (index > -1) {
      this.filteredDatasource[index].InUseBy = "";
      this.filteredDatasource[index].WebUrl = "";
      if (this.clickedCellRowData && this.clickedCellRowData.WebUrl) this.clickedCellRowData.WebUrl = "";
      this.dlxClient.GetDocumentLimited(this.filteredDatasource[index].Id).subscribe({
        next: (res) => {
          const _doc = this.buildDocumentGridData([res]);
          this.filteredDatasource[index] = _doc[0];
        }
      });
      this.grid1.refreshGridState();
    }
  }


  calculateGridWidth(): string {
    if (window.innerWidth > 1024) {
      return (window.innerWidth - 32) + 'px';
    } else {
      return (window.innerWidth - 5) + 'px';
    }
  }

  public contextmenu = false;
  public contextmenuX = 0;
  public contextmenuY = 0;
  public left = 0;
  public right = 0;
  public contextMenuHeight = 0;
  public subLeft = 0;
  public clickedCell: CellType | null = null;
  public clickedCellRowData!: IDocumentLimitedGrid;


  private selectedCategoryId!: string | null;
  isMobile!: boolean;
  isTablet!: boolean;
  isDesktop!: boolean;
  width!: number;

  @Input() case!: ICaseBE;

  @ViewChild('documentGrid', { read: IgxGridComponent, static: false })
  public documentGrid!: IgxGridComponent;


  @ViewChild('outlet', { read: ViewContainerRef }) outletRef!: ViewContainerRef;
  @ViewChild('content', { read: TemplateRef }) contentRef!: TemplateRef<any>;
  @ViewChild('imageDialog', { static: true }) public imageDialog!: IgxDialogComponent;

  public dropping: boolean = false;
  public documents: IDocumentLimitedBE[] = [];
  private datasource: IDocumentLimitedGrid[] = []
  public filteredDatasource: IDocumentLimitedGrid[] = []
  public documentCategories!: IDocumentCategoryBE[];
  public documentSubCategories!: IDocumentSubCategoryBE[] | null;

  public isSharePointDependent!: boolean;

  public isPdforNote(data: IDocumentLimitedGrid) {
    const ext = getFileExt(data.Filename);
    if (!ext) return true;
    if (ext === "pdf") return true
    return false
  }

  @ViewChild("fileDropRef", { static: false }) fileDropEl!: ElementRef;
  files: any[] = [];

  selected: boolean = false;

  fm = new FileManager();

  azRedirectUri!: string;

  azAccessToken!: string | null;

  azAccessTokenLookup() {
    let attempts = 1;
    const localstorageLookup = setInterval(() => {
      if (attempts > 9 || this.azAccessToken) {

        localStorage.removeItem("azAccessToken");
        clearInterval(localstorageLookup);
        let queuedDocument = localStorage.getItem("queuedDocument");
        let parsed: { data: IDocumentLimitedGrid, multiedit: boolean } = queuedDocument && JSON.parse(queuedDocument);
        if (!parsed) return
        localStorage.removeItem("queuedDocument")
        let cell = {} as CellType;
        let row = {} as RowType;
        row.data = parsed.data;
        cell.row = row;

        if (parsed.multiedit) {
          this.openFileforCollaboration(true)
        } else {
          this.openFileManager(true)
        }
      }
      this.azAccessToken = localStorage.getItem("azAccessToken");
      attempts++;
    }, 500);

  }
  sharePointSignIn() {
    this.dlxMsal.aquireToken(this.sys.microsoftGraphClientConfig!, true)
  }

  public overlaySettings = {
    positionStrategy: new ConnectedPositioningStrategy({
      horizontalDirection: HorizontalAlignment.Left,
      horizontalStartPoint: HorizontalAlignment.Right,
      verticalStartPoint: VerticalAlignment.Bottom
    }),
    scrollStrategy: new NoOpScrollStrategy()
  };


  public rerender() {
    this.outletRef.clear();
    this.outletRef.createEmbeddedView(this.contentRef);
  }

  updateGrid() {

  }

  checkDocumentFullRights() {
    const documentAccesslevel = this.userRights.checkAccess(UserAreaEnum.CASE_DOCUMENTS).accessId;
    if (documentAccesslevel === UserRightTypeEnum.NOT_LOADED) return false;
    if (documentAccesslevel === UserRightTypeEnum.LOOK) return false;
    if (documentAccesslevel === UserRightTypeEnum.FULL) return true;
    return false;
  }

  checkDocumentReadRights() {
    const documentAccesslevel = this.userRights.checkAccess(UserAreaEnum.CASE_DOCUMENTS).accessId;
    if (documentAccesslevel === UserRightTypeEnum.NOT_LOADED) return false;
    if (documentAccesslevel === UserRightTypeEnum.LOOK) return true;
    if (documentAccesslevel === UserRightTypeEnum.FULL) return true;
    return false;
  }

  openFileManager(queued = false) {

    let isSafari = false;
    document.body.classList.add('wait-cursor');
    // if (this.deviceService.getBrowserType() === "Safari") {
    //   safariHandle = window.open('', '_blank');
    // }

    isSafari = this.deviceService.getBrowserType() === "Safari"

    const rowData = this.clickedCellRowData;
    const fileExt = getFileExt(rowData.Filename);
    const checkedoutDocument = this.dcs.getCheckedoutDocument(rowData.Id);
    if (checkedoutDocument) {
      let url = checkedoutDocument.WebUrl || checkedoutDocument.PreviewUrl;
      // this.handleDocumentOpen(url ?? '', rowData, safariHandle);
      this.handleDocumentOpen(url ?? '', rowData);
      return;
    }

    if (rowData.DocumentTypeId === DocumentTypeEnum.NOTAT) {
      this.showDetailModal = true;
      this.DetailModal.open();
      return
    }

    switch (fileExt) {
      case "pdf":
        // if (safariHandle !== null) {
        //   this.openPdf(rowData, safariHandle);
        // } else {
        //   this.openPdf(rowData);
        // }
        this.openPdf(rowData, isSafari);
        break;
      case "msg":
        // this.handleMsgFile(rowData, safariHandle, queued);
        this.handleMsgFile(rowData, queued);
        break;
      case "jpg":
      case "jpeg":
      case "png":
      case "webp":
        this.handlePhotoFile(rowData);
        break;

      default:
        // this.handleOtherFileTypes(rowData, safariHandle, queued);
        this.handleOtherFileTypes(rowData, queued);
        break;
    }
  }

  isWindowOpen(id: string) {
    const opened = this.dcs.popupHandleLookup(id);
    if (!opened) return false
    opened.window.focus()
    return true;
  }

  handleMsgFile(rowData: IDocumentLimitedGrid, /*safariHandle: Window | null | undefined,*/ queued: boolean) {
    const pendingActivity = this.newOpenPendingActivity(rowData.DocumentName);

    if (!this.isDesktop && queued) {
      localStorage.setItem("queuedDocument", JSON.stringify({ data: rowData, multiedit: false }));
    }

    if (this.isWindowOpen(rowData.Id)) return;

    document.body.classList.add('wait-cursor');

    this.dlxMsal.useToken(this.azRedirectUri).subscribe({
      next: (token) => {
        this.dlxClient.UploadDocumentSharePointRead(rowData.Id, token).subscribe({
          next: (res) => {

            const driveName = res.DriveWebUrl.split("/").slice(-1)[0];
            const host = `${res.DriveWebUrl}/Forms/AllItems.aspx?`;
            const params = {
              filepath: encodeURIComponent(`/${driveName}/${res.SharePointPath}/${res.Name}`).replace('.', '%2E'),
              parent: encodeURIComponent(`/${driveName}/${res.SharePointPath}`)
            }
            const url = `${host}id=${params.filepath}&parent=${params.parent}`;

            document.body.classList.remove('wait-cursor');
            // const url = res.PreviewUrl;
            this.als.removeActivity(pendingActivity);

            // this.handleDocumentOpen(url, rowData, safariHandle);
            this.handleDocumentOpen(url, rowData);

            this.dcs.getCheckedOutDocuments(this.sys.userId);
            this.loadDocuments();
          },
          error: () => this.handleOpenFileError(rowData),
        });
      },
      error: () => this.handleOpenFileError(rowData),
    });
  }
  file: any;
  handlePhotoFile(rowData: IDocumentLimitedGrid) {

    if (!rowData.FileId) { console.error('error no FileId.'); return; }
    this.dlxClient.GetFileContent(rowData.FileId).subscribe({

      // next: (file) => this.downloadFile(file),
      next: (event) => {
        if (event.type === HttpEventType.Response) {
          const response = event as HttpResponse<Blob>;
          const fileData = response.body
          if (fileData) {
            const blob = new Blob([fileData], { type: 'application/octet-stream' })
            this.previewPhoto(blob);
            this.file = blob;
          }
        }
      },
      error: (error) => {
        this.handleOpenFileError(rowData)
      }
    });
  }


  decodedImageUrl: string = '';

  async previewPhoto(fileData: Blob) {
    if (fileData) {

      const dataurl = await this.blobToBase64(fileData)
      // const imageType = 'image/png';
      // const imageDataUrl = `data:${imageType};base64,${fileData.}`;
      this.decodedImageUrl = decodeURIComponent(dataurl);
      this.imageDialog.open();
      // window.open(imageDataUrl, '_blank');
    } else {
      console.error('File data is not a valid string.');
    }
  }


  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to convert blob to base64 string'));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }


  handleDocumentOpen(url: string, rowData: IDocumentLimitedGrid/*, safariHandle: Window | null | undefined*/) {

    const accessOrientedUrl = this.docUrl.getAccessOrientetUrl(url);
    const updateDataAndHandle = (windowHandle: any) => {
      this.filteredDatasource[this.filteredDatasource.findIndex(doc => doc.Id === rowData.Id)].WebUrl = accessOrientedUrl;
      this.addWindowHandle({
        window: windowHandle,
        windowId: rowData.Id,
        userId: this.sys.userId,
      });
    };

    if(this.isSafari) {
      this.openLinkSafari(accessOrientedUrl, rowData);
    } else { 
      const newWindow = window.open(accessOrientedUrl, '_blank');
      if (newWindow) updateDataAndHandle(newWindow);
    }

    // if (safariHandle !== null && safariHandle !== undefined) {
    //   safariHandle.location.href = accessOrientedUrl;
    //   updateDataAndHandle(safariHandle);
    //   safariHandle.focus();
    // } else {
    //   const newWindow = window.open(accessOrientedUrl, '_blank');
    //   if (newWindow) updateDataAndHandle(newWindow);
    // }

  }

  async handleOtherFileTypes(rowData: IDocumentLimitedGrid, /*safariHandle: Window | null,*/ queued: boolean) {
    const pendingActivity = this.newOpenPendingActivity(rowData.DocumentName);
    try {

      if (!this.isDesktop && queued) {
        localStorage.setItem("queuedDocument", JSON.stringify({ data: rowData, multiedit: false }));
      }

      if (this.isWindowOpen(rowData.Id)) return;

      document.body.classList.add('wait-cursor');


      this.dlxMsal.useToken(this.azRedirectUri).subscribe({
        next: (token) => {
          let caseDocumentAccess = this.checkDocumentFullRights();

          if (caseDocumentAccess) {
            this.dlxClient.UploadDocumentSharePoint(rowData.Id, true, false, token).subscribe({
              next: (res) => {
                if (!res.WebUrl) {
                  this.newOpenFailureActivity('No WebUrl found in upload response.');
                  return;
                }
                const accessUrl = this.docUrl.getAccessOrientetUrl(res.WebUrl);
                // this.handleDocumentOpen(accessUrl, rowData, safariHandle);
                this.handleDocumentOpen(accessUrl, rowData);
                this.dcs.getCheckedOutDocuments(this.sys.userId);
                this.loadDocuments();
                this.als.removeActivity(pendingActivity);
              },
              error: () => {
                this.als.removeActivity(pendingActivity);
                this.newOpenFailureActivity(`Kunne ikke åpne dokument, opplastning til SharePoint feilet.`);
                throw new Error('Kunne ikke åpne dokument, opplastning til SharePoint feilet.');
              }
            })
          } else {
            this.dlxClient.UploadDocumentSharePointRead(rowData.Id, token).subscribe({
              next: (readRes) => {
                if (!readRes.WebUrl) {
                  this.newOpenFailureActivity('No WebUrl found in upload response.');
                  return;
                }
                const accessUrl = this.docUrl.getAccessOrientetUrl(readRes.WebUrl);
                // this.handleDocumentOpen(accessUrl, rowData, safariHandle);
                this.handleDocumentOpen(accessUrl, rowData);
                this.dcs.getCheckedOutDocuments(this.sys.userId);
                this.loadDocuments();
                this.als.removeActivity(pendingActivity);
              },
              error: () => {
                this.als.removeActivity(pendingActivity);
                this.newOpenFailureActivity(`Kunne ikke åpne dokument, opplastning til SharePoint feilet.`);
                throw new Error('Kunne ikke åpne dokument, opplastning til SharePoint feilet.');
              }
            })
          }
        },
        error: () => {
          this.als.removeActivity(pendingActivity);
          this.newOpenFailureActivity(`Kunne ikke åpne dokument, SharePoint pålogging feilet.`);
          throw new Error('Kunne ikke åpne dokument, SharePoint pålogging feilet.');
        }

      })
    } catch {
      this.handleOpenFileError(rowData)
      this.als.removeActivity(pendingActivity);

      const errorActivity = newActivity({
        message: `Kunne ikke åpne dokumentet ${rowData.DocumentName}`,
        type: "failure",
        timestamp: new Date()
      });
      this.als.appendActivity(errorActivity);
    }
  }

  handleOpenFileError(rowData: IDocumentLimitedGrid) {
    const activity = newActivity({
      message: `Kunne ikke åpne dokumentet ${rowData.DocumentName}`,
      type: "failure",
      timestamp: new Date()
    });
    this.als.appendActivity(activity);
    document.body.classList.remove('wait-cursor');
  }


  openFileforCollaboration(queued: boolean = false) {

    let safariHandle: Window | null = null;
    // if (this.deviceService.getBrowserType() === "Safari") {
    //   safariHandle = window.open('', '_blank');
    // }

    let rowData = this.clickedCellRowData;
    let date = new Date();
    const pendingActivity = newActivity({
      message: `Venter på dokumentet ${rowData.DocumentName}.`,
      type: "info",
      timestamp: new Date()
    })
    this.als.appendActivity(pendingActivity)

    if (!this.isDesktop && queued) {
      localStorage.setItem("queuedDocument", JSON.stringify({ data: rowData, multiedit: true }))
    }

    this.dlxMsal.useToken(this.azRedirectUri).subscribe({
      next: (token) => {
        this.dlxClient.UploadDocumentSharePoint(rowData.Id, true, true, token).subscribe({
          next: (res) => {
            if (this.isSafari) {
              this.openLinkSafari(res.WebUrl, rowData);
            } else {
              const _w = window.open(res.WebUrl, '_blank');
              this.filteredDatasource[this.filteredDatasource.findIndex(doc => doc.Id === rowData.Id)].WebUrl = res.WebUrl;
              if (_w) {
                this.addWindowHandle({
                  window: _w,
                  windowId: rowData.Id,
                  userId: this.sys.userId,
                  parentRefrence: res.ParentReference
                })

                this.als.removeActivity(pendingActivity);

                const successActivity = newActivity({
                  message: `${rowData.DocumentName} ble åpnet`,
                  type: "success",
                  timestamp: new Date()
                })
                this.als.appendActivity(successActivity)
              }
            }

            this.dcs.getCheckedOutDocuments(this.sys.loggedInUser.Id)
            this.loadDocuments();
          },
          error: () => {
            const errorActivity = newActivity({
              message: `Kunne ikke åpne dokumentet ${rowData.DocumentName}`,
              type: "failure",
              timestamp: new Date()
            })
            this.als.appendActivity(errorActivity)
          }
        })
      }
    });
  }


  contextDeleteDocument() {
    let rowData = this.clickedCellRowData;

    if (confirm(SystemDialogEnum.SPECIFIC_DELETE_CONFIRMATION.replace('*doc_name*', rowData.DocumentName!))) {
      this.dlxClient.DeleteDocument(rowData.Id).subscribe({
        next: () => {
          this.grid1.deleteRow(rowData.Id);
          const activity = newActivity({
            message: `Dokumentet ${rowData.DocumentName} ble slettet`,
            // message: `Dokumentet ble slettet`,
            type: "success",
            timestamp: new Date()
          });
          this.als.appendActivity(activity)
        },

        error: (error) => {
          let message = SystemDialogEnum.DOCUMENT_DELETE_ERROR;
          if (error.error.includes('The DELETE statement conflicted with the REFERENCE constraint \"FK_OutgoingInvoice_')) {
            message = SystemDialogEnum.ACCOUNTING_DOCUMENT_DELETE_ERROR;
          }
          const activity = newActivity({
            message,
            type: "failure",
            timestamp: new Date()
          });
          this.als.appendActivity(activity)
        },
      })
    }
  }

  async contextDownloadDocument(withAnnotations: boolean = false) {
    let rowData = this.clickedCellRowData;
    try {
      if (!rowData.FileId) throw new Error('No FileId found.');
      if (rowData.FileId && rowData.Filename.endsWith('.pdf')) {
        this.getPdfForDownload(rowData.FileId, withAnnotations, rowData.DocumentName);
      } else {
        const fileContent = await this.getFileContent(rowData.FileId, rowData.Filename);
        if (fileContent) {
          this.downloadFile(fileContent, rowData.Filename);
        } else {
          throw new Error('No file content found.');
        }
        // this.getFileForDownload(rowData.FileId)
      }
    } catch (error) {
      const activity = newActivity({
        message: SystemDialogEnum.DOCUMENT_DOWNLOAD_ERROR.replace('*doc_name*', rowData.DocumentName!),
        type: "failure",
        timestamp: new Date()
      });
      this.als.appendActivity(activity)
    }
  }


  async getPdfForDownload(fileId: string, withAnnotations: boolean, fileName: string) {
    const pdfData = await fetch(`${this.dlxClient.webURL}/GetPdfFile?fileId=${fileId}&withAnnotations=${withAnnotations}&userId=${this.sys.loggedInUser.Id}`, {
      headers: {
        'Authorization': `Token ${this.dlxClient.token}`,
        "X-DLX-SessionId": this.dlxClient.sessionId,
        'Content-Type': 'application/pdf',
      }
    });

    if (!pdfData.ok) throw new Error('Error fetching pdf data.');
    const blob = await pdfData.blob();
    if (blob.size === 0) throw new Error('No data found in response.');



    this.downloadFile(blob, fileName)

  }

  // getFileForDownload(fileId: string) {
  //   this.dlxClient.GetFileContent(fileId).subscribe({
  //     next: (file) => this.downloadFile(file.FileData as string, file.Filename),
  //     error: (error) => {
  //       throw new Error(error);
  //     }
  //   })
  // }

  contextCheckInDocument() {

    this.showDetailModal = true;
    let rowData = this.clickedCellRowData;
    if (rowData.FileId) {
      this.dlxMsal.useToken(this.azRedirectUri).subscribe({
        next: (token) => {
          this.dlxClient.CheckInDocumentSharePoint(rowData.Id!, token).subscribe({
            next: () => {
              // this.loadDocuments();
              this.updateGridAfterCheckedIn(rowData.Id);
              this.dcs.getCheckedOutDocuments(rowData.Id)
            },
            error: (er) => {
              let error = CheckInErrorMessage(er.error);
              if (error) {
                const activity = newActivity({
                  message: error.message(rowData.DocumentName),
                  type: "failure",
                  timestamp: new Date()
                })
                this.als.appendActivity(activity)
              }
            }
          })
        }
      });
    }
  }

  contextOpenDetails() {
    // this.showDetailModal = true;
    this.fetchSubCategories(this.selectedCategoryId!)
    this.showDetailModal = true;
    this.DetailModal.open();

  }

  private fetchSubCategories(categoryId: string): void {
    if (!categoryId) return;
    this.dlxClient.GetDocumentSubCategorysByCategory(categoryId).subscribe({
      next: response => {
        this.documentSubCategories = response;
      },
      error: error => console.error('Error fetching subcategories:', error)
    });
  }

  contextOpenDocumentDesktopApp() {
    this.openDesktopApp()
    return

    let date = new Date();
    let rowData = this.clickedCellRowData;

    const pendingActivity = newActivity({
      message: `Venter på dokumentet ${rowData.DocumentName}.`,
      type: "info",
      timestamp: new Date()
    });
    this.als.appendActivity(pendingActivity);

    this.dlxMsal.useToken(this.azRedirectUri).subscribe({
      next: (token) => {
        this.dlxClient.UploadDocumentSharePoint(rowData.Id, true, true, token).subscribe({
          next: (res) => {
            let arr = (rowData as IDocumentLimitedBE).Filename!.split('.');
            const type = arr[arr.length - 1];

            const uriResult = this.createURIScheme(type, res);

            if (uriResult.success) {
              window.open(uriResult.uri, '_blank');
            } else {
              if (uriResult.uri === "") {

              } else {
                const errorActivity = newActivity({
                  message: `Kunne ikke åpne denne filtypen i Office.`,
                  type: "failure",
                  timestamp: new Date()
                });
                this.als.appendActivity(errorActivity);
                window.open(uriResult.uri, '_blank');
                this.handleOpenFileError(rowData)
              }
            }

            this.als.removeActivity(pendingActivity);
            this.dcs.getCheckedOutDocuments(this.sys.loggedInUser.Id)

            this.loadDocuments();
          },

        })
      },
      error: () => {
        this.als.removeActivity(pendingActivity);

        const tokenErrorActivity = newActivity({
          message: `Kunne ikke hente token for ${rowData.DocumentName}`,
          type: "failure",
          timestamp: new Date()
        });
        this.als.appendActivity(tokenErrorActivity);
      }

    })
  }


  contextPreviewDocument() {
    let date = new Date();
    let rowData = this.clickedCellRowData;
    // const rowData = this.$contextmenuComponent.target.row.data;
    const activity = newActivity({
      message: `Venter på dokumentet ${rowData.DocumentName}.`,
      type: "warning",
      timestamp: new Date()
    })
    // this.als.appendActivity(new Activity('doc', 'open', [rowData.DocumentName!], date, "warning"))
    this.dlxMsal.useToken(this.azRedirectUri).subscribe({
      next: (token) => {
        this.dlxClient.UploadDocumentSharePoint(rowData.Id, true, true, token).subscribe(res => {
          window.open(res.WebUrl, '_blank');
          this.dcs.getCheckedOutDocuments(this.sys.loggedInUser.Id)
          this.loadDocuments();
        }, () => {
          this.handleOpenFileError(rowData)
        })
      }
    });
  }


  downloadProgress: number | null = null;

  async getFileContent(fileId: string, fileName: string): Promise<Blob | null> {


    return new Promise((resolve, reject) => {
      this.dlxClient.GetFileContent(fileId).subscribe({
        next: (event) => {
          switch (event.type) {
            case HttpEventType.DownloadProgress:
              if (event.total) {
                this.downloadProgress = Math.round(100 * event.loaded / event.total);
              }
              break;
            case HttpEventType.Response:
              this.downloadProgress = null; // Reset the progress
              const response = event as HttpResponse<Blob>;
              const fileData = response.body;
              if (fileData) {
                const blob = new Blob([fileData], { type: 'application/octet-stream' }); // Set the correct type if known
                resolve(blob)
              }
              break;
          }

        },
        error: (error) => {
          reject(null)
        }
      })
    })
  }

  downloadFile(fileData: Blob, fileName: string) {
    if (!fileData) throw new Error('No file data found.');
    if (!fileName) {
      fileName = this.case.Title;
    };

    let blob = null;
    if (typeof fileData === 'string' && fileData !== null) {
      blob = new Blob([fileData], { type: 'application/octet-stream' });
    } else {
      blob = fileData;
    }



    try {
      const blob = new Blob([fileData], { type: fileData.type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName; // Replace with the desired file name
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error during download:', error);
    }



  }

  deleteFileLock() {
    let data = this.clickedCellRowData;
    if (data.FileId && data.InUseBy) {
      this.dlxClient.GetFileLockByFileId(data.FileId).subscribe({
        next: (lock) => {
          if (lock.Id) {
            this.dlxClient.DeleteFileLock(lock.Id).subscribe({
              next: () => {
                this.loadDocuments();
              }
            })
          }
        }
      })
    }
  }

  SaveCategoryOutputFromModal(ev: any) {
    const cat: { id: string, name: string } | null = ev.category;
    const subCat: { id: string, name: string } | null = ev.subCategory;
    const { ...data } = this.clickedCellRowData;
    if (cat) {
      data.DocumentCategoryName = cat.name;
      data.DocumentCategoryId = cat.id;
    }
    if (subCat) {
      data.DocumentSubCategoryName = subCat.name;
      data.DocumentSubCategoryId = subCat.id;
    }
    this.dlxClient.GetDocument(data.Id).subscribe({
      next: (res) => {

        res.DocumentCategoryId = cat?.id ?? null;
        res.DocumentSubCategoryId = subCat?.id ?? null;
        this.dlxClient.SaveDocumentWithNoFileDataReturn(res).subscribe({
          next: (res) => {
            const activity = newActivity({
              message: 'Dokumentkategori lagret',
              type: "success",
              timestamp: new Date()
            })
            this.als.appendActivity(activity)
          },
          error: error => {
            const activity = newActivity({
              message: "Lagring av dokumentkategori mislyktes",
              type: "failure",
              timestamp: new Date()
            })
            this.als.appendActivity(activity)
          },
          complete: () => { this.CategoryModal.close(); this.loadDocuments(); this.resetCategoryModal(); }
        })
      }
    })
  }


  resetCategoryModal() {
    setTimeout(() => {
      this.showCategoryModal = false
    }, 10);

  }

  openDocumentCategoryDialog(cell?: IDocumentLimitedGrid) {
    if (!cell) {
      cell = this.clickedCellRowData;
    }

    setTimeout(() => {
      this.CategoryModal.open();
      this.showCategoryModal = true;
    }, 10);

  }

  addWindowHandle({ window, windowId, userId, parentRefrence }: WindowWrapper) {
    this.dcs.addPopupHandle({ window, windowId, userId, parentRefrence })
  }

  getMSOCTXContent(sharepointEnabled: boolean): CtxItem[][] {

    let data = [
      [
        {
          label: "Åpne",
          action: () => this.openFileManager(),
          leftIcon: "open_in_new",
          disabled: sharepointEnabled && !this.gotDocumentReadPermissions,
          subItems: [
            {
              label: "Åpne i nettleser",
              action: () => this.openFileManager(),
              leftIcon: "open_in_new",
              disabled: sharepointEnabled && !this.gotDocumentReadPermissions,
            },
            {
              label: "Åpne for samarbeid",
              action: () => this.openFileforCollaboration(),
              leftIcon: "workspaces",
              disabled: sharepointEnabled && !this.gotDocumentReadPermissions,
            },
            {
              label: "Åpne I skrivebordsapplikasjon",
              action: () => this.contextOpenDocumentDesktopApp(),
              leftIcon: "file_open",
              disabled: sharepointEnabled && !this.gotDocumentReadPermissions,
            }
          ]
        },
        {
          label: "Åpne skrivebeskyttet",
          action: () => this.openMsoPreview(),
          leftIcon: "visibility",
          disabled: sharepointEnabled && !this.gotDocumentReadPermissions,
        },
        {
          label: "Sjekk inn",
          action: () => this.contextCheckInDocument(),
          leftIcon: "assignment_turned_in",
          disabled: !this.clickedCellRowData.InUseBy && !this.gotDocumentFullPermissions,
        },
        {
          label: "Detaljer",
          action: () => this.contextOpenDetails(),
          leftIcon: "info",
          disabled: !this.gotDocumentReadPermissions,
        },
      ], [
        {
          label: "Sett kategori",
          action: () => this.openDocumentCategoryDialog(),
          leftIcon: "edit",
          disabled: !this.gotDocumentFullPermissions,
        },
        {
          label: "Last ned",
          action: () => this.contextDownloadDocument(),
          leftIcon: "download",
          disabled: !this.gotDocumentReadPermissions,
        },
        {
          label: "Slett",
          action: () => this.contextDeleteDocument(),
          disabled: !this.gotDocumentFullPermissions,
          leftIcon: "delete",
        }
      ]
    ]
    if (!this.isWindowsDesktop) {
      const temp = data[0][0].subItems!.filter(item => item.leftIcon !== "file_open");
      data[0][0].subItems = temp;
    }

    return data;
  }
  getPDFCTXContent(): CtxItem[][] {
    return [
      [
        {
          label: "Åpne",
          action: () => this.openFileManager(),
          leftIcon: "open_in_new",
          disabled: !this.gotDocumentReadPermissions,
        },
        {
          label: "Detaljer",
          action: () => this.contextOpenDetails(),
          leftIcon: "info",
          disabled: !this.gotDocumentReadPermissions,
        },
      ],
      [
        {
          label: "Sett kategori",
          action: () => this.openDocumentCategoryDialog(),
          disabled: !this.gotDocumentFullPermissions,
          leftIcon: "edit",
        },
        {
          label: "Last ned",
          action: () => this.contextDownloadDocument(),
          disabled: !this.gotDocumentReadPermissions,
          leftIcon: "download",
          subItems: [
            {
              label: "Uten annotasjoner",
              action: () => this.contextDownloadDocument(),
              leftIcon: "playlist_remove",
              disabled: !this.gotDocumentReadPermissions,
            },
            {
              label: "Med annotasjoner",
              action: () => this.contextDownloadDocument(true),
              leftIcon: "playlist_add_check",
              disabled: !this.gotDocumentReadPermissions,
            },

          ]
        },
        {
          label: "Slett",
          action: () => this.contextDeleteDocument(),
          disabled: !this.gotDocumentFullPermissions,
          leftIcon: "delete",
        }
      ]
    ]
  }
  getDefaultCTXContent(sharepointEnabled: boolean): CtxItem[][] {
    return [
      [
        {
          label: "Åpne",
          action: () => this.openFileManager(),
          disabled: sharepointEnabled && !this.gotDocumentReadPermissions,
          leftIcon: "open_in_new",
        },
        {
          label: "Detaljer",
          action: () => this.contextOpenDetails(),
          disabled: !this.gotDocumentReadPermissions,
          leftIcon: "info",
        },

      ], [
        {
          label: "Sett kategori",
          action: () => this.openDocumentCategoryDialog(),
          disabled: !this.gotDocumentFullPermissions,
          leftIcon: "edit",
        },
        {
          label: "Last ned",
          action: () => this.contextDownloadDocument(),
          disabled: !this.gotDocumentReadPermissions,
          leftIcon: "download",
        },
        {
          label: "Slett",
          action: () => this.contextDeleteDocument(),
          disabled: !this.gotDocumentFullPermissions,
          leftIcon: "delete",
        }
      ]
    ]
  }
  getNotatCTXContent(): CtxItem[][] {
    return [
      [
        {
          label: "Åpne",
          action: () => this.openFileManager(),
          disabled: !this.gotDocumentReadPermissions,
          leftIcon: "open_in_new",
        },
        {
          label: "Detaljer",
          action: () => this.contextOpenDetails(),
          disabled: !this.gotDocumentReadPermissions,
          leftIcon: "info",
        },

      ], [
        {
          label: "Sett kategori",
          action: () => this.openDocumentCategoryDialog(),
          disabled: !this.gotDocumentFullPermissions,
          leftIcon: "edit",
        },
        {
          label: "Last ned",
          action: () => this.contextDownloadDocument(),
          disabled: !this.gotDocumentReadPermissions,
          leftIcon: "download",
        },
        {
          label: "Slett",
          action: () => this.contextDeleteDocument(),
          disabled: !this.gotDocumentFullPermissions,
          leftIcon: "delete",
        }
      ]
    ]
  }

  ctx_content!: CtxItem[][];

  getCTXContent(ext: string) {
    switch (ext.toLocaleLowerCase()) {
      case "docx":
      case "doc":
      case "docm":
      case "xlsx":
      case "xls":
      case "ppt":
      case "pptx":
        return this.getMSOCTXContent(!this.isLoggedInSharepoint);
      case "pdf":
        return this.getPDFCTXContent();
      default:
        return this.getDefaultCTXContent(!this.isLoggedInSharepoint);
    }
  }

  isSharePointConnected = false;



  // private updateCheckedInDocument(documentId: string) {
  // let document = this.filteredDatasource.find(doc => doc.Id === documentId)
  // if (document) {
  //   document.InUseBy = null;
  //   this.filteredDatasource = [...this.filteredDatasource]
  // } else {
  //   console.error(`document with id ${documentId} not found `)
  // }
  // }

  private updateDocumentGridRow({ document, rowId }: IUpdateDocumentGridRow) {

    this.dlxClient.GetDocumentLimited(document.Id).subscribe({
      next: (res) => {
        const record = this.buildDocumentGridData([res])[0];
        if (rowId) {
          this.grid1.updateRow(record, rowId)
        } else {
          this.grid1.addRow(record);
        }
      }
    })


  }

  loadDocuments(caseId = this.case.Id) {
    this.isLoading = true;
    if (caseId === undefined) {
      let pathArray = window.location.pathname.split('/')
      caseId = pathArray[pathArray.length - 1]
    }

    this.dlxClient.FindDocuments(null, null, caseId, null, null, null, [], null, null, null, "", "", "", false, "", true, true, true, true).subscribe({
      next: response => {
        this.documents = response;

        const gridData = this.buildDocumentGridData(response);
        this.datasource = gridData;
        this.filteredDatasource = gridData;

        if (this.filteredDatasource.length === 0) {
          this.emptyGridMessage = "Det ligger ingen dokumenter på saken";
        }


        if (this.documents.length === 0) {
          this.dropping = true;
        }
        this.isLoading = false;
      },
      error: error => {
        this.isLoading = false;
      }
    });

  }

  disabledHidden: boolean = false;
  hideDisabled() {
    this.disabledHidden = !this.disabledHidden;
    if (this.disabledHidden) {
      this.filteredDatasource = this.datasource.filter(item => !this.shouldDisableRow(item));
    } else {
      this.filteredDatasource = this.datasource;
    }
  }

  buildDocumentGridData(docs: IDocumentLimitedBE[]) {
    let tmp: IDocumentLimitedGrid[] = [];

    (docs as IDocumentLimitedGrid[]).forEach((item, index) => {
      let tempFile = item.Filename ? item.Filename!.split('.') : [""];
      if (item.DocumentTypeId === DocumentTypeEnum.NOTAT) {
        item.Icon = fileIcon(DocumentTypeEnum.NOTAT);
      }
      else {
        item.Icon = fileIcon(tempFile[tempFile.length - 1]);
      }

      item.ChangedDate = DateDisplay(item.DateChanged!);
      item.DocNr = String(item.DocumentNr);
      try {
        if (this.filteredDatasource.length > 0) {
          if (this.filteredDatasource[index].WebUrl && this.filteredDatasource[index].WebUrl !== "") {
            item.WebUrl = this.filteredDatasource[index].WebUrl;
          } else {
            item.WebUrl = "";
          }
        }
      } catch (error) {
      }

      tmp.push(item);

    })

    return tmp;
  }

  isOpenXMLDocument() {
    if (!this.clickedCellRowData) return false;
    const ext = getFileExt(this.clickedCellRowData.Filename);
    return ext === "docx" || ext === "docm" || ext === "xlsx" || ext === "pptx";
  }

  sharepointDependent(row: IDocumentLimitedGrid) {
    let ext = (getFileExt(row.Filename))
    if (ext === "") {
      this.isSharePointDependent = false;
      return false;
    }
    else if (ext === "pdf") {
      this.isSharePointDependent = false;
      return false;
    }
    else {
      this.isSharePointDependent = false;
      return true;
    }
  }

  loadDocumentCategories() {
    this.dlxClient.GetDocumentCategorysByIndex(null).subscribe({
      next: (res) => {
        this.documentCategories = res;
      }
    })
  }


  public rowStyles = {
    color: (row: RowType) => {
      return this.shouldDisableRow(row.data) || (this.sharepointDependent(row.data) && !this.isLoggedInSharepoint) ? "#BCBCBC" : "#000";
    },
    filter: (row: RowType) => {
      return this.shouldDisableRow(row.data) || (this.sharepointDependent(row.data) && !this.isLoggedInSharepoint) ? "grayscale(100%)" : "none";
    },
    cursor: (row: RowType) => {
      return this.shouldDisableRow(row.data) || (this.sharepointDependent(row.data) && !this.isLoggedInSharepoint) ? "not-allowed" : "pointer";
    },
    'pointer-events': (row: RowType) => {
      return this.shouldDisableRow(row.data) || (this.sharepointDependent(row.data) && !this.isLoggedInSharepoint) ? "none" : "auto";
    }
  };

  private shouldDisableRow(data: any): boolean {
    const isAccountingDocument = AccountingDocumentTypes.map(type => type.toUpperCase()).includes(data.DocumentTypeId.toUpperCase());
    const hasAccess = this.checkAccountingDocumentsRights();
    return isAccountingDocument && !hasAccess;
  }


  checkAccountingDocumentsRights(): boolean {
    const documentAccesslevel = this.userRights.checkAccess(UserAreaEnum.ACCOUNT_DOCUMENTS).accessId;
    if (documentAccesslevel === UserRightTypeEnum.NOT_LOADED) return false;
    if (documentAccesslevel === UserRightTypeEnum.LOOK) return true;
    if (documentAccesslevel === UserRightTypeEnum.FULL) return true;
    return false;
  }

  hasAccountingDocumentsFullRights(): boolean {
    const documentAccesslevel = this.userRights.checkAccess(UserAreaEnum.ACCOUNT_DOCUMENTS).accessId;
    if (documentAccesslevel === UserRightTypeEnum.FULL) return true;
    return false;
  }

  canEditCategory(data: any): boolean {
    const isAccountingDocument = AccountingDocumentTypes.map(type => type.toUpperCase()).includes(data.DocumentTypeId.toUpperCase());
    return !(isAccountingDocument && !this.hasAccountingDocumentsFullRights());
  }

  public handleCellEdit(event: IGridEditEventArgs) {
    const column = event.column;
    if (column?.field === 'Ordered') {
      const rowData = event.rowData;
      if (!rowData) {
        return;
      }
    }
  }

  public openDocument(multiedit = false) {
    let rowData = this.clickedCellRowData;
    let date = new Date();
    const activity = newActivity({
      message: `Venter på dokumentet ${rowData.DocumentName}.`,
      type: "warning",
      timestamp: new Date()
    })

    this.dlxMsal.useToken(this.azRedirectUri).subscribe({
      next: (token) => {


        this.dlxClient.UploadDocumentSharePoint(rowData.Id, true, multiedit, token).subscribe({
          next: (res) => {
            window.open(res.WebUrl, '_blank');

            const activity = newActivity({
              message: `${rowData.DocumentName} ble åpnet.`,
              type: "success",
              timestamp: new Date()
            })
            this.als.appendActivity(activity)
            // this.als.appendActivity(new Activity('doc', 'open', [rowData.DocumentName!], date, "success"))
            this.dcs.getCheckedOutDocuments(this.sys.loggedInUser.Id)
            this.loadDocuments();
          },
          error: () => {
            const activity = newActivity({
              message: "Kunne ikke åpne dokumentet",
              type: "failure",
              timestamp: new Date()
            })
            this.als.appendActivity(activity)
            // this.als.appendActivity(new Activity('doc', 'open', [rowData.DocumentName!], date, "failure"))
          }
        })
      }
    })
  }

  createPdfUrl(data: IDocumentLimitedBE){
    this.dlxClient.FileLinearizePDF(data.FileId!).subscribe(() => {
      this.pdfHREF = this.router.serializeUrl(
        this.router.createUrlTree([`/pdfviewer/${data.Id}`])
      );
    })
  }


  openPdf(data: IDocumentLimitedBE, isSafarai: boolean) {

    const existingHandle = this.dcs.popupHandleLookup(data.Id);
    if (existingHandle) {
      existingHandle.window.focus();
      return
    }

    this.dlxClient.FileLinearizePDF(data.FileId!).subscribe(() => {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/pdfviewer/${data.Id}`])
      );

      const handle = isSafarai ? this.openLinkSafari(url, data) : this.openPdfStandard(url, data);
      if (!handle) {
        alert('Popup vinduet ble blokkert av nettleseren')
        return
      }
      this.addWindowHandle({
        window: handle,
        windowId: data.Id,
        userId: this.sys.userId
      })



      // if (safariHandle !== null && safariHandle !== undefined) {
      //   safariHandle.location.href = url;
      // } else {
      //   let handle: Window | null = null;
      //   if (!this.isDesktop) {
      //     window.open(url, '_blank');
      //   } else {
      //     handle = window.open(url, data.Id, params);
      //   }

      //   if (!handle) {
      //     alert('Popup vinduet ble blokkert av nettleseren')
      //     return
      //   }

      //   this.addWindowHandle({
      //     window: handle,
      //     windowId: data.Id,
      //     userId: this.sys.userId
      //   })
      // }

    });
  }

  openPdfStandard(url: string, data: IDocumentLimitedBE) {
    let params = `status=no,location=no,toolbar=no,menubar=no`;
    let handle: Window | null = null;

    if (!this.isDesktop) {
      window.open(url, '_blank');
    } else {
      handle = window.open(url, data.Id, params);
    }

    if (!handle) {
      return null
    }

    return handle;


  }
  openLinkSafari(url: string, data: IDocumentLimitedBE) {
    let params = `status=no,location=no,toolbar=no,menubar=no`;
    let handle: Window | null = null;

    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank'; // Opens in a new tab or window
    link.rel = 'noopener noreferrer';

    document.body.appendChild(link);

    link.addEventListener('click', (event) => {
      if (!this.isDesktop) {
        handle = window.open(link.href, '_blank');
      } else {
        handle = window.open(url, data.Id, params);

      }
      event.preventDefault();
    })

    link.click();

    if (!handle) {
      return null
    }

    return handle;
  }

  public checkInDocument(id: string) {
    this.dlxMsal.useToken(this.azRedirectUri).subscribe({
      next: (token) => {
        this.dlxClient.CheckInDocumentSharePoint(id, token).subscribe(res => { })
      }
    })

  }

  onDragOver(event: DragEvent) {
    event.preventDefault();

    this.dropping = true;
  }

  onDragLeave() {
    this.dropping = false;
  }

  async onFileDropped(files: FileList) {
    if (this.checkDocumentFullRights() === false) {
      this.newOpenFailureActivity("Du har ikke tilgang til å laste opp dokumenter");
      return
    }
    let documents: IDocumentBE[] = [];

    for (let i = 0; i < files.length; i++) {
      documents.push(this.fm.createDocumentWithDefaultValues(newDoc(), files[i], this.sys.loggedinUserContact.Id, this.case));
    }
    this.uploadDocument(documents, files, 0);

    this.dropping = false;
  }

  uploadDocument(documentList: IDocumentBE[], fileList: FileList, index: number, shouldRetry: boolean = true) {
    if (this.checkDocumentFullRights() === false) {
      this.newOpenFailureActivity("Du har ikke tilgang til å lage nye dokumenter");
      return
    }

    if (index === fileList.length) {
      this.loadDocuments();
      return
    }

    this.dlxClient.UploadDocument(documentList[index], fileList[index]).subscribe({
      next: () => {
        this.uploadDocument(documentList, fileList, index + 1);
      },
      error: () => {
        if (shouldRetry) {
          this.uploadDocument(documentList, fileList, index, false);
        }
      }
    })
  }



  documentCategoryChange({ value, cell }: IGridSelectChangeEvent) {
    const { Id, Name } = value.newSelection.value;
    const { rowIndex } = cell.id;
    const row = this.grid1.getRowByIndex(rowIndex);
    const data = row.data as IDocumentLimitedGrid;
    data.DocumentCategoryName = Name;
    data.DocumentCategoryId = Id;
    this.grid1.updateRow(data, rowIndex)

    this.dlxClient.GetDocument(data.Id).subscribe({
      next: (res) => {
        res.DocumentCategoryId = Id;
        this.dlxClient.SaveDocumentWithNoFileDataReturn(res).subscribe({
          next: () => {
            this.dlxClient.GetDocumentSubCategorysByCategory(Id).subscribe({
              next: (res) => {
                data.SubCategorys = res;
                this.grid1.updateRow(data, rowIndex);
              }
            })

          }
        })
      }
    })
  }

  getSubCategories(cell: IgxGridCell) {
    this.documentSubCategories = null;
    const id = (this.grid1.getRowByIndex(cell.id.rowID).data as IDocumentLimitedBE).DocumentCategoryId;
    if (!id) return;
    this.dlxClient.GetDocumentSubCategorysByCategory(id).subscribe({
      next: (res) => {
        this.documentSubCategories = res;

      }
    })
  }


  documentSubCategoryChange({ value, cell }: IGridSelectChangeEvent) {
    const { Id, Name } = value.newSelection.value;
    const { rowIndex } = cell.id;
    const row = this.grid1.getRowByIndex(rowIndex);
    const data = row.data as IDocumentLimitedGrid;
    data.DocumentSubCategoryName = Name;
    data.DocumentSubCategoryId = Id;
    this.grid1.updateRow(data, rowIndex)

    this.dlxClient.GetDocument(data.Id).subscribe({
      next: (res) => {
        res.DocumentSubCategoryId = Id;
        this.dlxClient.SaveDocumentWithNoFileDataReturn(res).subscribe({
          next: (res) => {
          }
        })
      }
    })
  }

  public dateTimeOptions = {
    format: 'dd.MM.Y, HH:mm'
  };



  public openNewDocmentFromTemplateModal() {
    this.showNewDocumentFromTemplateModal = true;
    this.NewDocumentFromTemplateModal.open();

    const sub = this.NewDocumentFromTemplateModal.closed.subscribe({
      next: () => {
        this.showNewDocumentFromTemplateModal = false;
        sub.unsubscribe();
      }
    })

  }


  public rightClick(eventArgs: IGridCellEventArgs) {

    try {
      const cell = eventArgs.cell;
      const event = eventArgs.event as PointerEvent;
      this.clickedCellRowData = cell.row.data;
      this.rowId = cell.id!.rowID

      if (cell.row.data.DocumentCategoryId) {
        this.selectedCategoryId = cell.row.data.DocumentCategoryId;
      }

      this.rerender();
      event.preventDefault();
      this.ctx_content = (cell.row.data.FileId === null) ? this.getNotatCTXContent() : this.getCTXContent(getFileExt(cell.row.data.Filename));
      let contextLength = 0;

      for (let i = 0; i < this.ctx_content.length; i++) {
        for (let j = 0; j < this.ctx_content[i].length; j++) {
          contextLength++;
        }
      }

      let contextMenuHeight = (contextLength * 34) + 33

      var height = this.grid1.nativeElement.offsetHeight;

      const clickPosition = event.clientY - this.grid1.nativeElement.getBoundingClientRect().top;
      this.contextmenuX = event.clientX > this.grid1.nativeElement.offsetWidth - 150 ? event.clientX - 160 : event.clientX + 10;
      this.contextmenuY = (clickPosition > (height - contextMenuHeight) - 20) ? clickPosition - contextMenuHeight + 50 - event.offsetY : clickPosition + 60 - event.offsetY;

      if (event.clientX > this.grid1.nativeElement.offsetWidth - 354) {
        this.left = -160;
        this.right = 0;
        this.subLeft = -110;
      } else {
        this.left = 100;
        this.right = 0;
        this.subLeft = 100;
      }

      this.contextmenu = true;
    } catch (error) {
      console.error(error)
    }
  }

  public disableContextMenu() {
    if (this.contextmenu) {
      this.contextmenu = false;
    }
  }

  public cellSelection(event: IGridCellEventArgs) {
    this.clickedCellRowData = event.cell.row.data;
    this.selectedCategoryId = this.clickedCellRowData.DocumentCategoryId;
    this.rowId = event.cell.id!.rowID;
    this.contextmenu = false;
    this.selected = true;
    this.showDetailModal = true;
    this.showNewDocumentFromTemplateModal = true;
    this.showCategoryModal = true;
    if(this.clickedCellRowData.Filename.endsWith('.pdf')){
      this.createPdfUrl(this.clickedCellRowData);
    }
    this.rerender();
    this.selection.emit(this.clickedCellRowData)
  }

  uploadSingleDocument(event: Event, menu: IgxOverlayOutletDirective) {
    const target = event.target as HTMLInputElement
    const file = target.files && target.files[0];
    if (target.files) {
      const document = [this.fm.createDocumentWithDefaultValues(newDoc(), target.files[0], this.sys.loggedinUserContact.Id, this.case)];
      this.uploadDocument(document, target.files, 0);
    }
  }

  uploadMultipleDocuments(event: Event, menu: IgxOverlayOutletDirective) {
    const target = event.target as HTMLInputElement
    const files = target.files;
    if (files) {
      let documents: IDocumentBE[] = [];
      for (let i = 0; i < files.length; i++) {
        documents.push(this.fm.createDocumentWithDefaultValues(newDoc(), files[i], this.sys.loggedinUserContact.Id, this.case));
      }
      this.uploadDocument(documents, files, 0);
    }
  }

  openIntegrationRequestMail() {
    window.location.href = `mailto:support@datalexsoftware.no?subject=Aktivere%20SharePoint%20integrasjon%20for%20${this.sys.loggedInUserAccount.AccountsOwner.Name}&body=Hei!%20Vi%20%C3%B8nsker%20%C3%A5%20aktivere%20Datalex-SharePoint%20integrasjonen%20for%20v%C3%A5rt%20kontor%0D%0A%0D%0A`;
  }

  openFile() {
    this.openFileManager();
  }

  async openMsoPreview() {
    const rowData = this.clickedCellRowData;
    const pendingActivity = this.newOpenPendingActivity(rowData.DocumentName);
    try {

      this.dlxMsal.useToken(this.azRedirectUri).subscribe({
        next: (token) => {
          this.dlxClient.UploadDocumentSharePointRead(rowData.Id, token).subscribe({
            next: (res) => {
              if (!res.WebUrl) {
                this.newOpenFailureActivity('No WebUrl found in upload response.');
                return;
              }

              const viewUrl = this.docUrl.createReadOnlyUrl(res.WebUrl);
              const viewWindow = window.open(viewUrl, '_blank');

              if (!viewWindow) {
                this.newOpenFailureActivity(`Kunne ikke åpne dokumentet ${rowData.DocumentName}.`);
                return;
              }

              this.dcs.addPopupHandle({
                window: viewWindow,
                windowId: this.clickedCellRowData.Id,
                userId: this.sys.userId,
              });

              this.dcs.getCheckedOutDocuments(this.sys.userId);
              this.loadDocuments();
              this.als.removeActivity(pendingActivity);
            },
            error: (error) => {
              this.newOpenFailureActivity(`Kunne ikke åpne dokumentet ${rowData.DocumentName}.`);
            }
          })
        }
      })

    }
    catch {
      this.als.removeActivity(pendingActivity);

      this.newOpenFailureActivity(`Kunne ikke åpne forhåndsvisning for ${rowData.DocumentName}.`);
    }
  }


  disableScroll() {
    document.body.classList.add('no-scroll');
  }

  enableScroll() {
    document.body.classList.remove('no-scroll');
  }


  createURIScheme(type: string, document: any): { success: boolean, uri: string } {
    let accessLevel = this.userRights.checkAccess(UserAreaEnum.CASE_DOCUMENTS).accessId;
    //Hvis rettighetene ikke har lastet enda, returner en tom uri
    if (accessLevel === UserRightTypeEnum.NOT_LOADED) {
      return {
        success: false,
        uri: ""
      };
    }

    let isLook = accessLevel === UserRightTypeEnum.LOOK;

    switch (type) {
      case "doc":
      case "docx":
        return {
          success: true,
          uri: `${isLook ? applicationURL.ms_word_r : applicationURL.ms_word_rw}${document.DriveWebUrl}/${document.SharePointPath}/${document.Name}`
        };

      case "xls":
      case "xlsx":
        return {
          success: true,
          uri: `${isLook ? applicationURL.ms_excel_r : applicationURL.ms_excel_rw}${document.DriveWebUrl}/${document.SharePointPath}/${document.Name}`
        };

      case "ppt":
      case "pptx":
        return {
          success: true,
          uri: `${isLook ? applicationURL.ms_ppt_r : applicationURL.ms_ppt_rw}${document.DriveWebUrl}/${document.SharePointPath}/${document.Name}`
        };

      default:
        if (document.WebUrl === null) {
          return {
            success: false,
            uri: ""
          };
        }
        return {
          success: false,
          uri: isLook ? this.docUrl.getAccessOrientetUrl(document.WebUrl) : document.WebUrl
        };
    }

  }

  async openDesktopApp() {
    const rowData = this.clickedCellRowData;

    const pendingActivity = newActivity({
      message: `Venter på dokumentet ${rowData.DocumentName}.`,
      type: "info",
      timestamp: new Date()
    });
    this.als.appendActivity(pendingActivity);

    try {

      this.dlxMsal.useToken(this.azRedirectUri).subscribe({
        next: (token) => {
          let caseDocumentAccess = this.checkDocumentFullRights();
          if (caseDocumentAccess) {
            this.dlxClient.UploadDocumentSharePoint(rowData.Id, true, false, token).subscribe({
              next: (res) => {
                this.openDocumentDesktopApp(rowData, res, pendingActivity)
              }
            })
          } else {
            this.dlxClient.UploadDocumentSharePointRead(rowData.Id, token).subscribe({
              next: (readRes) => {
                this.openDocumentDesktopApp(rowData, readRes, pendingActivity)
              }
            })
          }
        },
        error: () => {
          this.als.removeActivity(pendingActivity);
          this.newOpenFailureActivity(`Kunne ikke åpne dokument, SharePoint pålogging feilet.`);

        }

      })


    } catch (error: any) {
      this.als.removeActivity(pendingActivity);
      this.newOpenFailureActivity(`Kunne ikke åpne dokument, ${error.message}.`);
    }

  }

  openDocumentDesktopApp(rowData: IDocumentLimitedGrid, result: any, pendingActivity: Activity) {
    let arr = (rowData as IDocumentLimitedBE).Filename!.split('.');
    const type = arr[arr.length - 1];

    const uriResult = this.createURIScheme(type, result);

    if (uriResult.success) {
      window.open(uriResult.uri, '_blank');
    } else {
      if (uriResult.uri === "") {

      } else {
        this.als.removeActivity(pendingActivity);
        const errorActivity = newActivity({
          message: `Kunne ikke åpne denne filtypen i Office.`,
          type: "failure",
          timestamp: new Date()
        });
        this.als.appendActivity(errorActivity);
        window.open(uriResult.uri, '_blank');
        this.handleOpenFileError(rowData)
      }
    }

    this.als.removeActivity(pendingActivity);
    this.dcs.getCheckedOutDocuments(this.sys.loggedInUser.Id)

    this.loadDocuments();
  }

  newOpenPendingActivity(docName: string): Activity {
    const pendingActivity = newActivity({
      message: `Venter på dokumentet ${docName}.`,
      type: "info",
      timestamp: new Date()
    });
    this.als.appendActivity(pendingActivity);

    return pendingActivity
  }
  newOpenFailureActivity(message: string): Activity {
    const failureActivity = newActivity({
      message: message,
      type: "failure",
      timestamp: new Date()
    });
    this.als.appendActivity(failureActivity);

    return failureActivity;
  }
  newOpenSuccessActivity(docName: string): Activity {
    const successActivity = newActivity({
      message: `${docName} ble åpnet`,
      type: "success",
      timestamp: new Date()
    });
    this.als.appendActivity(successActivity);

    return successActivity;
  }

  closeDetailModal() {
    this.showDetailModal = false;
  }

  resetDetailModal() {
    setTimeout(() => {
      this.showDetailModal = false
    }, 1000);
  }


  resetShowNewDocumentFromTemplateModal() {
    setTimeout(() => {
      this.showNewDocumentFromTemplateModal = false;
    }, 1000);

  }

}