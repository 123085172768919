import { Component, OnInit, Input } from '@angular/core';
import { RandomId } from 'src/app/util/RandomId';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'dlx-checkbox',
    templateUrl: './dlx-checkbox.component.html',
    styleUrls: ['./dlx-checkbox.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class DlxCheckboxComponent implements OnInit {

  constructor() { }
  defaultId = RandomId();
  @Input() value!: boolean | null;
  @Input() label!: string;
  @Input() name: string = this.defaultId;
  @Input() id: string = this.defaultId;
  @Input() disabled: boolean = false;


  ngOnInit(): void {
  }

}
