@if(keyFigures) {
  <app-heading class="spacer" *ngIf="title" [value]="title"></app-heading>
  <app-label-input [title]="'Fast timesats'" [editable]="true" (onChange)="data.setHourlyRate($event)"
    [value]="data.getHourlyRate()" [display]="data.getHourlyRate() | norwegianNumberFormat" />
  <app-label-input [title]="'Budsjett'" [editable]="true" (onChange)="data.setBudget($event)" [value]="data.getBudget() || 0"
    [display]="(data.getBudget() || 0) | norwegianNumberFormat" />
  <app-label-input [title]="'Belastet'"
    [value]="keyFigures.TotalChargedWithoutNotInvoiceable | norwegianNumberFormat" />
  <app-label-input [title]="'Ikke fakturerbart'"
    [value]="keyFigures.TotalChargedNotInvoiceable | norwegianNumberFormat" />
  <app-label-input [title]="'Totalt Belastet'" [value]="keyFigures.TotalCharged |  norwegianNumberFormat" />
  <app-label-input [title]="'Ikke Fakturert honorar'" [type]="'currency'"
    [value]="keyFigures.NotInvoicedHourRegistrations | norwegianNumberFormat" />
  <app-label-input [title]="'Ikke fakturerte omkostninger'"
    [value]="keyFigures.NotInvoicedCostRegistrations | norwegianNumberFormat" />
  <app-label-input [title]="'Ikke fakturerte utlegg'" [value]="keyFigures.NotInvoicedOutlay | norwegianNumberFormat" />
  <app-label-input [title]="'Klientmidler'" [value]="keyFigures.CaseClientFunds | norwegianNumberFormat" />
}