<div class="component_title">
  <app-heading *ngIf="title">{{title}}</app-heading>
  <span class="money_laudery_assesment-wrapper">Hvitvask: {{ caseMoneyLaunderingRisk === null ? "Ikke vur." :
    caseMoneyLaunderingRisk }}</span>
</div>

<div class="case-info-wrapper">
  <div class="case_number">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput id="caseNumber" class=" search-input" name="caseNumber" autocomplete="off" type="number"
        [(ngModel)]="caseNumber" readonly />
      <label igxLabel for="caseNumber">Saksnummer:</label>
    </igx-input-group>
  </div>
  <div class="case_ref" *ngIf="screen.width > 475">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput id="caseRef" class=" search-input" name="caseRef" autocomplete="off" type="string"
        [(ngModel)]="caseRef" (ngModelChange)="data.setReference($event ? $event : null)" readonly />
      <label igxLabel for="caseRef">Referanse:</label>
    </igx-input-group>
  </div>
  <div class="case_started-date">
    <igx-date-picker #datePicker id="caseIncDate" [displayDensity]="'compact'" inputFormat="dd.MM.yyyy" type="border"
      todayButtonLabel="I dag" cancelButtonLabel="Avbryt" (click)="datePicker.open()" [value]="data.getStartedDate()"
      (valueChange)="data.setStartedDate(toLocalIsoString($event, true)); log(data)"
      [disabled]="!hasCaseManagementAccess">
      <label igxLabel for="caseIncDate">Innk. dato:</label>
    </igx-date-picker>
  </div>
  <div class="case_money-laudery money_laudery_assesment-wrapper">
    <span>Hvitvask: </span> <br>
    <span class="money_laudery_assesment">{{ caseMoneyLaunderingRisk === null ? "Ikke vur." :
      caseMoneyLaunderingRisk }}</span>
  </div>
  <igx-input-group class="case-title" displayDensity="compact" type="border">
    <input igxInput id="caseTitle" class=" search-input" name="caseTitle" autocomplete="off" type="string"
      [(ngModel)]="caseTitle" (ngModelChange)="data.setTitle($event)" [disabled]="!hasCaseManagementAccess" />
    <label igxLabel for="caseTitle">Tittel:</label>
  </igx-input-group>
  <igx-input-group class="case-description" displayDensity="compact" type="border">
    <textarea igxInput name="caseDescription" autocomplete="off" type="text" rows="4" style="resize: none;"
      [(ngModel)]="caseDescription" (ngModelChange)="data.setDescription($event)"
      [disabled]="!hasCaseManagementAccess"></textarea>

    <label igxLabel for="Description">Beskrivelse:</label>
  </igx-input-group>

  <div class="case_account-name">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput id="caseAccountName" name="caseAccountName" autocomplete="off" type="string"
        [(ngModel)]="caseAccountName" readonly />
      <label igxLabel for="caseAccountName">Regnskap:</label>
    </igx-input-group>
  </div>


  <div class="case_status">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput id="caseStatus" name="caseStatus" autocomplete="off" type="string" [(ngModel)]="caseStatus"
        readonly />
      <label igxLabel for="caseStatus">Status:</label>
    </igx-input-group>

  </div>
  <div class="case_closed-date" *ngIf="screen.width > 475">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput id="caseClosedDate" name="caseClosedDate" autocomplete="off" type="string"
        [(ngModel)]="caseClosedDate" readonly />
      <label igxLabel for="caseClosedDate">Avsluttet dato:</label>
    </igx-input-group>
  </div>
  <div class="case_arcive-number" *ngIf="screen.width > 475" [class.caseNumber-nondesktop]="!isDesktopDevice">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput id="caseArchvieNr" name="caseArchvieNr" autocomplete="off" type="string"
        [(ngModel)]="caseArchiveNumber" readonly />
      <label igxLabel for="caseArchvieNr">Arkivnummer:</label>
    </igx-input-group>
  </div>
</div>