import { Component, ComponentRef, OnInit, Input, AfterViewInit, ViewChild, QueryList, EventEmitter, Output } from '@angular/core';
import { CellType, IGridEditDoneEventArgs, IRowSelectionEventArgs, IgxGridCell, IgxGridComponent, IgxRippleDirective, IgxButtonDirective, IgxColumnComponent, IgxFilterCellTemplateDirective, IgxCellEditorTemplateDirective } from '@infragistics/igniteui-angular';
import { ModalHubAccessService } from 'src/app/components/UI/modal-hub/access.service';
import { DatalexClient, IDocumentCategoryBE, IDocumentSubCategoryBE } from '@datalex-software-as/datalex-client';
import { GridMethodsService } from 'src/app/services/grid-methods.service';
import { IDocumentLimitedGrid } from '../document-grid/document-grid.component';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { FormsModule } from '@angular/forms';
import { GridFilterInputComponent } from '../../../../UI/grid-filter-input/grid-filter-input.component';
import { NgIf, NgStyle, NgClass } from '@angular/common';

type TOutput = {
  name: string,
  id: string
}
export interface IModalOutputType {
  isChanged: boolean,
  category: TOutput | null,
  subCategory: TOutput | null
}

@Component({
    selector: 'app-document-category-editor',
    templateUrl: './document-category-editor.component.html',
    styleUrls: ['./document-category-editor.component.scss'],
    standalone: true,
    imports: [NgIf, IgxRippleDirective, IgxButtonDirective, NgStyle, IgxGridComponent, IgxColumnComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent, NgClass, IgxCellEditorTemplateDirective, FormsModule]
})
export class DocumentCategoryEditorComponent implements OnInit, AfterViewInit {

  @Output() categoryOutput: EventEmitter<any> = new EventEmitter();
  @Output() closeCategoryModal: EventEmitter<any> = new EventEmitter();

  // self!: ComponentRef<DocumentCategoryEditorComponent>;
  @Input() data!: IDocumentLimitedGrid | null;

  editCaseCategories = false;
  btnEnabled = false;

  grid_0_empty = "Fant ingen dokument kategorier"
  grid_1_empty = "Velg en kategori"

  editGrid_0_empty = "Lag en ny dokument kategori"
  editGrid_1_empty = "Velg en kategori"


  // data_0!: Promise<IDocumentCategoryBE[]>;
  // data_0!: Promise<IDocumentCategoryBE[]>;
  // data_1!: Promise<IDocumentSubCategoryBE[]>;

  categories: IDocumentCategoryBE[] = [];
  subCategories: IDocumentSubCategoryBE[] = [];

  edit_data_0: IDocumentCategoryBE[] = [];
  edit_data_1: IDocumentSubCategoryBE[] = [];


  fetchedSubCategories: IDocumentSubCategoryBE[][] = [];

  output: IModalOutputType = {
    isChanged: false,
    category: null,
    subCategory: null
  }

  constructor(private dlxClient: DatalexClient, private sys: SystemCacheService, private access: ModalHubAccessService, public gridMethods: GridMethodsService) { }

  ngOnInit(): void {
    if (!this.data) return;
    // try {

    //   this.dlxClient.GetDocumentCategorysByIndex(this.data.CaseId).subscribe({
    //     next: (response) => {
    //       if (response) {

    //         this.data_0 = new Promise((resolve, reject) => {
    //           resolve(response)
    //         })

    //         this.edit_data_0 = response.filter(cat => cat.CaseId)

    //       }
    //     }
    //   })
    // }
    // catch (err) {

    // }
    this.dlxClient.GetDocumentCategorysByIndex(this.data.CaseId).subscribe({
      next: (response) => {
        this.categories = response;
      },
    })

    

    if (this.data.DocumentCategoryId) {
      // this.output.category = {
      //   name: this.data.DocumentCategoryName,
      //   id: this.data.DocumentCategoryId
      // }
      // if (this.data.DocumentSubCategoryId) {
      //   this.output.subCategory = {
      //     name: this.data.DocumentSubCategoryName,
      //     id: this.data.DocumentSubCategoryId
      //   }
      // }

      this.fetchSubCategories(this.data.DocumentCategoryId)

      // this.dlxClient.GetDocumentSubCategorysByCategory(this.data.DocumentCategoryId).subscribe({
      //   next: (response) => {
      //     if (response) {
      //       if (!!response.length) {
      //         this.grid_1_empty = "Finnes ingen underkategorier"
      //       }
      //       this.data_1 = new Promise((resolve, reject) => {
      //         resolve(response)
      //       })
      //     }
      //   }
      // })
    }
  }

  private fetchSubCategories(categoryId: string): void {
    if (!categoryId) return;
    this.dlxClient.GetDocumentSubCategorysByCategory(categoryId).subscribe({
      next: response => {
        
        this.subCategories = response;
        if (!response.length) {
          this.grid_1_empty = "Finnes ingen underkategorier"
        }
      },
      error: error => console.error('Error fetching subcategories:', error)
    });
  }


  ngAfterViewInit(): void {

  }

  onGrid0RowSelecetion(e: IRowSelectionEventArgs) {
    let selection: IDocumentCategoryBE = e.newSelection[0];
    this.btnEnabled = true;
    this.output.category = { name: selection.Name, id: selection.Id };
    let existing = this.fetchedSubCategories.find(subcategory => subcategory.some(sub => sub.DocumentCategoryId === selection.Id));
    if (existing === undefined) {
      //   this.dlxClient.GetDocumentSubCategorysByCategory(selection.Id).subscribe({
      //     next: (response) => {
      //       if (response) {
      //         this.fetchedSubCategories.push(response);
      //         this.data_1 = new Promise((resolve, reject) => {
      //           resolve(response)
      //         })
      //       }
      //     }
      //   })
      // } else {
      //   this.data_1 = new Promise((resolve, reject) => {
      //     resolve(existing!)
      //   })
      this.fetchSubCategories(selection.Id);
    }

  }

  onGrid1RowSelecetion(e: IRowSelectionEventArgs) {
    let selection: IDocumentSubCategoryBE = e.newSelection[0];

    this.output.subCategory = { name: selection.Name, id: selection.Id };
    

  }



  selectedCategory: IDocumentCategoryBE | null = null;

  onEditGrid0RowSelecetion(event: any, grid: IgxGridComponent) {
    let selection = event.newSelection[0] as string;
    this.edit_data_1 = []
    

    this.selectedCategory = grid.getRowByKey(selection).data;
    if (selection && selection !== '00000000-0000-0000-0000-000000000000') {
      this.dlxClient.GetDocumentSubCategorysByCategory(selection).subscribe({
        next: (res) => {
          if (res.length === 0) {
            this.editGrid_1_empty = "Lag en ny underkategori"
          }
          this.edit_data_1 = res;
        }
      });
    }
  }

  selectedSubCategory: IDocumentSubCategoryBE | null = null;
  onEditGrid1RowSelecetion(event: any) {
    let selection = event.newSelection[0] as IDocumentSubCategoryBE
    this.selectedSubCategory = selection;
  }

  createNewCaseCategory(grid: IgxGridComponent) {
    this.dlxClient.NewDocumentCategory().subscribe({
      next: (res) => {
        
        grid.addRow(res);
        grid.cdr.detectChanges();

        let cells = grid.getRowByKey(res.Id).cells;

        if (cells) {
          cells.forEach(cell => {
            cell.editMode = true;
            cell.editValue = ''
            setTimeout(() => {
              document.getElementById("catInput")?.focus();
            }, 100)

          });
        }
      }
    })
  }

  createNewCaseSubCategory(grid: IgxGridComponent) {
    if (this.selectedCategory) {
      this.dlxClient.NewDocumentSubCategory().subscribe({
        next: (res) => {
          grid.addRow(res);
          grid.cdr.detectChanges();
          let cells = grid.getRowByKey(res.Id).cells;

          if (cells) {
            cells.forEach(cell => {
              cell.editMode = true;
              cell.editValue = '';
              setTimeout(() => {
                document.getElementById("subCatInput")?.focus();
              }, 100)
            });
          }

        }
      })
    }
  }

  cancleNew(cell: CellType) {
    cell.editMode = false;
    cell.grid.deleteRowById(cell.id?.rowID);
    cell.grid.cdr.detectChanges();
  }

  deleteCategory(grid: IgxGridComponent) {
    const id = grid.selectedRows[0]
    this.dlxClient.DeleteDocumentCategory(id).subscribe({
      next: () => {
        grid.deleteRowById(id)
        this.selectedCategory = null;
      }
    })
  }

  deleteSubCategory(grid: IgxGridComponent) {
    const id = grid.selectedRows[0]
    this.dlxClient.DeleteDocumentSubCategory(id).subscribe({
      next: () => {
        grid.deleteRowById(id)
        this.selectedSubCategory = null;
      }
    })
  }

  onCellEditExit0(event: IGridEditDoneEventArgs) {
    if (!this.data) return;
    let cat = event.rowData as IDocumentCategoryBE;
    cat.CaseId = cat.IndexId = this.data.CaseId;
    cat.InUse = true;
    this.dlxClient.SaveDocumentCategory(cat).subscribe({
      next: (res) => {
      }
    })

  }
  onCellEditExit1(event: IGridEditDoneEventArgs) {
    let subCat = event.rowData as IDocumentSubCategoryBE;
    subCat.DocumentCategoryId = this.selectedCategory!.Id;
    this.dlxClient.SaveDocumentSubCategory(subCat).subscribe({
      next: (res) => {

      }
    })
  }


  categoryEditComplete(cell: CellType) {
    cell.update(cell.editValue);
    

    this.dlxClient.SaveDocumentCategory(cell.row.data).subscribe({
      next: (res) => {
        cell.editMode = false;
        cell.grid.cdr.detectChanges();
      }
    })
  }

  subCategoryEditComplete(cell: CellType) {
    cell.update(cell.editValue);

    let subCat = cell.row.data as IDocumentSubCategoryBE;
    subCat.DocumentCategoryId = this.selectedCategory!.Id;

    this.dlxClient.SaveDocumentSubCategory(subCat).subscribe({
      next: (res) => {
        cell.editMode = false;
        cell.grid.cdr.detectChanges();
      }
    })
  }

  close() {
    this.closeCategoryModal.emit();
    

  }


  log(e: any) {
    
  }

  save() {
    
    this.categoryOutput.emit(this.output)
    // this.shouldModalClose.emit()
    this.data = null;
    

  }
  cancle() {
    this.close();
    this.output.isChanged = false;
    document.getElementById('modalCloseBtn')?.click();

  }
}
