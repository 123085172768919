const CheckInErrorMessage = (error: string): { message: (title: string) => string, type: string } | null => {
  const errors = [
    {
      errorMatch: "The resource you are attempting to access is locked",
      message: (title: string) => `Dokumentet / filen er låst, har du husket å lukke "${title}"?`,
      type: 'locked'
    },
    {
      errorMatch: "The resource could not be found.",
      message: (title: string) => `"${title}" ser ut til å være sjekket inn allerede?`,
      type: 'notfound'
    },
  ];
  const response = errors.find((err) => error.includes(err.errorMatch));

  return response ? { message: response.message, type: response.type } : null;
};

export default CheckInErrorMessage;
