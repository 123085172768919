import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject, ChangeDetectorRef, TemplateRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IGridCellEventArgs, ISimpleComboSelectionChangingEventArgs, IgxDialogComponent, IgxGridComponent, IgxOverlayService, SortingDirection, IgxDialogTitleDirective, IgxIconComponent, IgxLabelDirective, IgxSimpleComboComponent, IgxComboItemDirective, IgxInputGroupComponent, IgxInputDirective, IgxSuffixDirective, IgxButtonDirective, IgxRippleDirective, IgxDialogActionsDirective, IgxColumnComponent, IgxCellTemplateDirective, IgxCellHeaderTemplateDirective, IgxGridFooterComponent, IgxFilterCellTemplateDirective } from '@infragistics/igniteui-angular';
import { CaseBE } from 'src/app/classes/CaseBE';
import { ActivityLogService } from 'src/app/components/UI/activity-log-overlay/activity-log.service';
import { DatalexExceptionHandlerService } from 'src/app/services/datalex-exception-handler.service';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { ContactSectorIdEnum, ContactSectorsArray, IContactSector } from 'src/app/util/ContactSectorUtil';
import { UserAreaEnum, UserRightTypeEnum } from 'src/app/util/UserRightUtil';
import { RoleCode, RoleTypes, RoleTypeCategoryIdEnum } from 'src/app/util/RoleTypeEnum';
import { DatalexClient, ICaseBE, ICaseContactBE, IContactBE, IContactExtendedBE, IRoleTypeBE, IRoleTypeCategoryBE } from '@datalex-software-as/datalex-client';
import { GridMethodsService } from 'src/app/services/grid-methods.service';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { Subscription, firstValueFrom } from 'rxjs';
import newActivity from 'src/app/classes/Activity/Actvity';
import { FormGroup, FormControl, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridFilterInputComponent } from '../../../UI/grid-filter-input/grid-filter-input.component';
import { GridItemCountComponent } from '../../../UI/grid-item-count/grid-item-count.component';
import { ContactsComponent } from '../../contacts/contacts.component';
import { NgIf, NgClass, NgStyle } from '@angular/common';
import { DeviceService } from 'src/app/services/device.service';
import { SystemDialogEnum } from 'src/app/util/SystemDialogEnum';
import { constructionTrolley } from '@igniteui/material-icons-extended';
import { EventEmitterService, IForcedEventTypeEnum } from 'src/app/services/event-emitter.service';

type ContactType = IContactExtendedBE | ICaseContactBE;

interface IRoleForm {
  roleCategoryId: FormControl<string | null>,
  roleTypeId: FormControl<string | null>,
  contactName: FormControl<string | null>,
  contact: FormControl<IContactExtendedBE | null>
}

@Component({
  selector: 'app-case-roles',
  templateUrl: './case-roles.component.html',
  styleUrls: ['./case-roles.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, IgxDialogComponent, IgxDialogTitleDirective, IgxIconComponent, FormsModule, ReactiveFormsModule, IgxLabelDirective, IgxSimpleComboComponent, IgxComboItemDirective, IgxInputGroupComponent, IgxInputDirective, IgxSuffixDirective, IgxButtonDirective, IgxRippleDirective, IgxDialogActionsDirective, ContactsComponent, NgStyle, IgxGridComponent, IgxColumnComponent, IgxCellTemplateDirective, IgxCellHeaderTemplateDirective, IgxGridFooterComponent, GridItemCountComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent]
})

export class CaseRolesComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() addNewRoleEvent: EventEmitter<{ contactId: string }> = new EventEmitter();
  @Output() roleAdded = new EventEmitter<void>();


  @ViewChild("caseRoleGrid", { read: IgxGridComponent })
  public grid!: IgxGridComponent;

  @ViewChild('roleChangeWarning') public roleChangeWarning!: IgxDialogComponent;
  @ViewChild('roleDeleteWarning') public roleDeleteWarning!: IgxDialogComponent;
  @ViewChild('contactSearchDialog') public contactSearchDialog!: IgxDialogComponent;
  @ViewChild('roleFormDialog') public roleFormDialog!: IgxDialogComponent;

  @ViewChild('contactsComponent', { read: TemplateRef }) contentRef!: TemplateRef<any>;
  private _data!: CaseBE;
  @Input() set data(data: CaseBE) {
    this._data = data;
    this.caseRoleDs = this.data.getCaseContacts();
  };
  get data() {
    return this._data;
  }

  @Output() onSave = new EventEmitter<ICaseContactBE[]>()


  @Output() mandatoryRolesCheck = new EventEmitter<boolean>();
  @Output() changeOriginCheck = new EventEmitter<string>();


  isMobile!: boolean;
  isTablet!: boolean;
  isDesktop!: boolean;
  private subscriptions = new Subscription();

  hasEditRights: boolean = false;
  canAddOrEditRole: boolean = false;
  rolePermissionsLevel = 0;
  canDeleteRole: boolean = false;


  constructor(
    @Inject(IgxOverlayService) public overlayService: IgxOverlayService,
    private dlxClient: DatalexClient,
    private als: ActivityLogService,
    private dlxEx: DatalexExceptionHandlerService,
    public router: Router,
    public aRoute: ActivatedRoute,
    public sys: SystemCacheService,
    private rights: UserRightsProviderService,
    public gms: GridMethodsService,
    public combo: ComboSelectFunctionsService,
    public screenSize: ScreenSizeService,
    private cdr: ChangeDetectorRef,
    public deviceService: DeviceService,
    private eventEmitter: EventEmitterService
  ) {



    this.subscriptions.add(this.screenSize.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
      this.cdr.detectChanges(); // Manually trigger change detection
    }));

    this.subscriptions.add(this.screenSize.isTablet$.subscribe(isTablet => {
      this.isTablet = isTablet;
    }));

    this.subscriptions.add(this.screenSize.isDesktop$.subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    }));
    this.initializeForm();
  }

  getRolePermissions(): number {
    const contactAccess = this.rights.checkAccess(UserAreaEnum.CONTACTS).accessId !== UserRightTypeEnum.NONE;
    if (contactAccess) {
      const caseAccess = this.rights.checkAccess(UserAreaEnum.CASE_MANAGMENT);
      switch (caseAccess.accessId) {
        case UserRightTypeEnum.FULL:
          return 2;
        case UserRightTypeEnum.OWN_CASES:
          return 2;
        case UserRightTypeEnum.LOOK:
          return 1;
        default:
          return 0;
      }
    }
    return 0;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();

  }

  _caseRoleDs!: ICaseContactBE[];

  set caseRoleDs(val: ICaseContactBE[]) {
    this._caseRoleDs = val;

  }

  get caseRoleDs() {
    return this._caseRoleDs;
  }

  selectedCaseContact: ICaseContactBE | null = null;  // selected case contact from the grid
  rowButtonAddRoleText: string = "Ny rolle";

  // userRightsLoaded: boolean = false;
  contactRights!: string;

  showExistingWarning = false;
  existingWarningProp = "denne rollen.";

  ISAId = RoleCode.ISA.toLocaleLowerCase();  // Intern saksansvarlig
  ISBId = RoleCode.ISB.toLocaleLowerCase();
  KHAId = RoleCode.KH.toLocaleLowerCase(); // KH Klient hovedadressat
  MHAId = RoleCode.MH.toLocaleLowerCase(); // motpart hovedadressat


  klientCategoryId = "1cbffce0-c0f0-4b90-9437-ecfbe08d96cf";
  internCategoryId = "6034096e-1574-4571-8b9a-4c663a1af63e";

  showDialog = false

  roleTypeCategories!: IRoleTypeCategoryBE[]

  isMandatory = false;

  selectedRoleType: string | null = null;
  contactSectors: IContactSector[] = ContactSectorsArray;

  showResults: boolean = false;

  selectedRowCaseCanEdit = false;
  selectedRowCasecanDelete = false;
  selectedRowCaseCanAddSimilar = false;
  activeUsersEnabled = false;

  ContactSearchResultDataSource!: IContactExtendedBE[];
  noResult: boolean = false;

  caseContactDialogTitle = "";

  IsaOrKhSelected: boolean = false;


  /*  */

  // roleForm = new FormGroup<IRoleForm>({
  //   roleCategoryId: new FormControl(''),
  //   roleTypeId: new FormControl(''),
  //   contactName: new FormControl(''),
  //   contact: new FormControl(null),
  // });

  roleForm = new FormGroup<IRoleForm>({
    roleCategoryId: new FormControl({ value: this.internCategoryId, disabled: this.ISAorKHSelected() }),
    roleTypeId: new FormControl({ value: '', disabled: this.ISAorKHSelected() }),
    contactName: new FormControl(''),
    contact: new FormControl(null)
  });


  contactSearchInput: string = '';

  roleTypes!: IRoleTypeBE[] | null;
  sortedRoleTypes!: IRoleTypeBE[] | null;
  activeRoleTypes: IRoleTypeBE[] = [];

  InternRoleTypes!: IRoleTypeBE[];

  roleTypeCategoryId = "";
  roleTypeId = "";
  roleTypeCode = '';
  roleTypeName = '';
  contact = "";

  currentRoleType!: IRoleTypeBE | null;

  originalContactId!: string;
  currentContactId!: string;

  CantactNameControl: any;

  contactSectorId: string = ContactSectorIdEnum.M;
  dialogContactsSource!: IContactExtendedBE[];
  contactSearchOrigin = "case-roles";

  restrictedRoleTypeIds: Set<string> = new Set([
    this.ISAId, // saksansvarlig
    this.ISBId, // Saksbehandler
    this.KHAId, // KH Klient hovedadressat
    this.MHAId //  motpart hovedadressat
  ]);

  /* KH og ISA som er mandatory */

  ngOnInit(): void {

    try {
      // this.userRightsLoaded = !!this.sys.userRights;

      const caseAccess = this.rights.checkAccess(UserAreaEnum.CASE_MANAGMENT);
      this.hasEditRights = caseAccess.accessId === UserRightTypeEnum.FULL || caseAccess.accessId === UserRightTypeEnum.OWN_CASES;

      const contactAccess = this.rights.checkAccess(UserAreaEnum.CONTACTS);
      this.contactRights = contactAccess.accessName;

      this.canAddOrEditRole = this.canDeleteRole = contactAccess.accessId !== UserRightTypeEnum.NONE;
      this.rolePermissionsLevel = this.getRolePermissions();




    } catch {
      this.sys.isReady.subscribe({
        next: () => {
          // this.userRightsLoaded = !!this.sys.userRights;
          this.contactRights = this.rights.checkAccess(UserAreaEnum.CONTACTS).accessName;
          this.rolePermissionsLevel = this.getRolePermissions();

        }
      })
    }

    this.eventEmitter.getForceUpdateEvent().subscribe((event) => {
      if (event.type === IForcedEventTypeEnum.AddedMissingRoleForHours) {
        this.caseRoleDs = this.data.getCaseContacts();
        this.grid.selectedRows = [];

      }
    });
    this.eventEmitter.getForceUpdateEvent().subscribe((event) => {
      if (event.type === IForcedEventTypeEnum.AddedMissingRoleForCosts) {
        this.caseRoleDs = this.data.getCaseContacts();
      }
    });

    this.CantactNameControl = this.roleForm.get('contactName');

    this.roleTypeCategoryId = this.roleForm.get('roleCategoryId')?.value || this.internCategoryId;
    this.roleTypeId = this.roleForm.get('roleTypeId')?.value || "";
    this.contact = this.CantactNameControl.value;

    this.setSectorId();

    const allExists = !this.contactSectors.find(s => s.Id === '00000000-0000-0000-0000-000000000000')
    if (allExists) {
      this.contactSectors.push({
        Id: '00000000-0000-0000-0000-000000000000',
        Name: 'Alle',
        Code: 'A'
      });
    }
    this.getRoleTypes();

    this.dlxClient.GetRoleTypeCategorys().subscribe({
      next: (roleTypeCategories) => {
        this.roleTypeCategoryId = roleTypeCategories.filter(cat => cat.Code === "I")[0].Id

        this.roleForm.get('roleCategoryId')?.setValue(this.roleTypeCategoryId)

        this.roleTypeCategories = roleTypeCategories.sort((a, b) => {
          return a.Order - b.Order
        });

        this.activeRoleTypes = this.getActiveRoleTypes(this.roleTypeCategoryId);
        this.initializeForm();
      },
      error: this.dlxEx.exception.bind(this)
    });

  }


  ngAfterViewInit() {
    try {
      this.afterViewInited();
    } catch (error) {
      this.sys.isReady.subscribe({
        next: () => {
          this.afterViewInited();
        }
      })
    }
  }

  afterViewInited() {
    if (!this.grid) {
      return;
    }

    this.grid.sort({ fieldName: 'ContactNumber', dir: SortingDirection.Asc, ignoreCase: false })
    this.aRoute.queryParams.subscribe({
      next: (params: Params) => {
        if (params['sender'] === 'newcontact') {
          this.dlxClient.GetContactExtendedById(params['id']).subscribe({
            next: (response) => {
              try {

                this.roleForm.patchValue({
                  contactName: `${response.Number} - ${response.Name}`,
                  contact: response,
                  roleCategoryId: params['rkid'],
                  roleTypeId: params['rid']
                })

                this.activeRoleTypes = this.getActiveRoleTypes(params['rkid'])

                this.currentRoleType = this.activeRoleTypes.filter(rt => rt.Id === params['rid'])[0]

                this.caseContactDialogTitle = params['cdt']


                if (this.caseContactDialogTitle === "Ny Rolle") {
                  this.toAddNew()
                } else {
                  this.toEdit()
                }

                this.searchedContact = response;
                this.roleFormDialog.open()


              } catch (error) {

              }
            }
          })
        }
      }
    })

    this.roleFormDialog.opening.subscribe(() => {
      if (this.ISAorKHSelected()) {
        this.roleForm.get('roleCategoryId')?.disable();
        this.roleForm.get('roleTypeId')?.disable();

      } else {
        this.roleForm.get('roleCategoryId')?.enable();
        this.roleForm.get('roleTypeId')?.enable();
      }
    });
    this.roleFormDialog.closing.subscribe(() => {
      if (!this.searchedContact) {
        this.resetState();
      }
      this.roleForm.get('roleCategoryId')?.enable();
      this.roleForm.get('roleTypeId')?.enable();
    });
  }

  canAddNewRole(formRoleTypeId: string): boolean {
    if (this.restrictedRoleTypeIds.has(formRoleTypeId)) {
      return !this.caseRoleDs.some(caseRole => caseRole.RoleTypeId === formRoleTypeId); // check if it exist .some return boolean 
    }
    return true;
  }

  canEditRole(formRoleTypeId: string, currentCaseRoleId: string): boolean {
    if (this.restrictedRoleTypeIds.has(formRoleTypeId)) {
      return !this.caseRoleDs.some(caseRole =>
        caseRole.RoleTypeId === formRoleTypeId && caseRole.Id !== currentCaseRoleId);
    }
    return true;
  }

  getRoleTypes() {
    this.dlxClient.GetRoleTypes().subscribe({
      next: (roleTypes) => {
        this.roleTypes = roleTypes;
        this.sortedRoleTypes = roleTypes.sort((a, b) => {
          return a.Order - b.Order
        });
      },
      error: this.dlxEx.exception.bind(this)
    })
  }

  getActiveRoleTypes(categoryId: string): IRoleTypeBE[] {
    if (this.sortedRoleTypes) {
      return this.sortedRoleTypes.filter(rt => rt.RoleTypeCategoryId.toUpperCase() === categoryId.toUpperCase());
    } else {
      return []
    }
  }

  initializeForm() {
    this.roleForm.setValue({
      roleCategoryId: this.internCategoryId,
      roleTypeId: '',
      contactName: '',
      contact: null
    });
    this.activeRoleTypes = this.getActiveRoleTypes(this.internCategoryId);
  }

  public lookupContact() {
    this.contactSectorId = this.roleTypeCategoryId.toUpperCase() === this.internCategoryId.toUpperCase() ? ContactSectorIdEnum.M : ContactSectorIdEnum.A;
    const searchInput = this.CantactNameControl.value;

    this.queryParams = {
      sender: "case",
      id: this.data.Id,
      rkid: this.roleForm.get('roleCategoryId')?.value,
      rid: this.roleForm.get('roleTypeId')?.value,
      cdt: this.caseContactDialogTitle
    }

    if (!searchInput) {
      this.dialogContactsSource = [];
      this.showDialog = true
      this.contactSearchDialog.open();
      return
    }

    this.dlxClient.SearchContacts(searchInput, this.contactSectorId).subscribe({
      next: (contacts) => {
        if (contacts.length === 1) {
          this.resultFromDialgSearch(contacts[0])
          this.searchedContact = contacts[0];
          this.roleForm.patchValue({
            contact: contacts[0],
          });

          this.currentContactId = contacts[0].Id;
          return
        }

        this.dialogContactsSource = contacts;
        this.showDialog = true
        this.contactSearchDialog.open();
        this.contactSearchInput = searchInput
      },
      error: this.dlxEx.exception.bind(this)
    })
  }

  resultFromDialgSearch(foundContact: IContactExtendedBE) {
    this.contactSearchDialog.close();
    this.setContactFormValue(foundContact);
    this.searchedContact = foundContact;
    this.roleForm.patchValue({
      contact: foundContact,
    });
    this.currentContactId = foundContact.Id;
  }

  setContactFormValue(contact: ContactType) {
    if ('Number' in contact && 'AllNames' in contact) {
      this.CantactNameControl.setValue(`${contact.Number} - ${contact.AllNames}`);
    } else {
      this.CantactNameControl.setValue(`${contact.ContactNumber} - ${contact.ContactName}`);
    }
  }

  addNewRole!: boolean;
  editRole!: boolean;

  toAddNew() {
    this.addNewRole = true;
    this.editRole = false;
  }

  toEdit() {
    this.addNewRole = false;
    this.editRole = true;
  }

  currentEditingContact!: ICaseContactBE;

  searchedContact!: IContactExtendedBE | null;

  setRoleTypeProps(contact: ICaseContactBE): ICaseContactBE {
    const updatedContact = { ...contact };

    updatedContact.RoleTypeCategoryId = this.roleTypeCategoryId;
    updatedContact.RoleTypeId = this.currentRoleType!.Id!;
    updatedContact.RoleTypeCode = this.currentRoleType!.Code!;
    updatedContact.RoleTypeName = this.currentRoleType!.Name!;

    return updatedContact;
  }

  onFormSubmit() {

    if (this.addNewRole) {
      this.onNew();
      this.roleFormDialog.close();
      return;
    }
    if (this.editRole) {
      // this.roleChangeWarning.open();
      this.onEdit();
      this.roleFormDialog.close();
    }
  }

  onNew() {
    if (this.searchedContact === null) {
      console.error('no searched contact');
      return;
    }

    const formRoleTypeId = this.roleForm.get('roleTypeId')?.value || '';

    if (formRoleTypeId === '') {
      console.error('no RoleTypeId from form');
      return;
    }

    if (this.checkForDuplicateRoles({ contactId: this.searchedContact.Id, roleTypeId: formRoleTypeId }) !== undefined) {

      this.showExistingWarning = true;
      const activity = newActivity({
        message: 'Kontakt med valgt rolle er allerede lagt til.',
        type: "warning",
        timestamp: new Date()
      })

      this.als.appendActivity(activity)
      return;
    }

    if (!this.canAddNewRole(formRoleTypeId)) {
      this.showExistingWarning = true;
      const activity = newActivity({
        message: 'Saken har allerede denne rollen.',
        type: "warning",
        timestamp: new Date()
      })

      this.als.appendActivity(activity)
      return;
    }


    this.dlxClient.NewCaseContact().subscribe({
      next: (newCaseContact) => {
        try {
          const caseContact = this.setCaseContactProps(newCaseContact, this.searchedContact, false, true);
          if (!this.currentRoleType) {
            return
          }
          const gridData = this.setGridData(caseContact, this.currentRoleType, this.searchedContact!);
          this.caseRoleDs = [gridData, ...this.caseRoleDs]
          const _cc = this.data.getCaseContacts();
          this.data.setCaseContacts([caseContact, ..._cc]);
          this.changeOriginCheck.emit("CaseRoles");


          this.checkIfMandatoryRolesExist();
        } catch (error) {

        }
      }
    });
  }


  onEdit() {
    ;
    if (!this.selectedCaseContact) {
      throw new Error("No selected case contact");
    }

    else if (confirm(SystemDialogEnum.ROLE_CHANGE_CONFIRMATION)) {

      const contactChanged = this.originalContactId !== this.currentContactId;

      if (contactChanged) {

        if (!this.currentContactId) {
          console.error('no selected contact');
          return;
        }

        const formRoleTypeId = this.roleForm.get('roleTypeId')?.value || '';

        if (formRoleTypeId === '') {
          console.error('no RoleTypeId from form');
          return;
        }

        if (this.checkForDuplicateRoles({ contactId: this.currentContactId, roleTypeId: formRoleTypeId }) !== undefined) {
          this.showExistingWarning = true;
          const activity = newActivity({
            message: 'Kontakt med valgt rolle er allerede lagt til.',
            type: "warning",
            timestamp: new Date()
          })

          this.als.appendActivity(activity)
          return;
        }

        this.removeCaseContactFromBE(this.selectedCaseContact.Id)
        console.log("removeCaseContactFromBE");

        this.dlxClient.NewCaseContact().subscribe({
          next: (newCaseContact) => {
            try {



              const newUpdatedCaseContact = this.setCaseContactProps(newCaseContact, this.searchedContact, true, true);

              if (!this.currentRoleType) {
                return
              }

              const gridData = this.setGridData(newCaseContact, this.currentRoleType, this.searchedContact!);

              console.log("gridData", gridData);

              this.caseRoleDs = this.caseRoleDs.filter(cc => cc.Id !== this.selectedCaseContact?.Id);
              console.log("this.caseRoleDs before", this.caseRoleDs);
              this.caseRoleDs = [gridData, ...this.caseRoleDs]
              console.log("this.caseRoleDs after", this.caseRoleDs);

              const ccSource = this.data.getCaseContacts();
              const updatedCaseContacts = [newUpdatedCaseContact, ...ccSource];
              this.data.setCaseContacts(updatedCaseContacts);
              this.changeOriginCheck.emit("caseRoles");


              this.checkIfMandatoryRolesExist();
            } catch (error) {
              const ccSource = this.data.getCaseContacts();
              if (this.selectedCaseContact) {
                const recoveredCaseContacts = [this.selectedCaseContact, ...ccSource];
                this.data.setCaseContacts(recoveredCaseContacts);
                this.changeOriginCheck.emit("caseRoles");

              } else {
                console.error('no selected contact');
              }

            }
          },
          error: () => {
            const ccSource = this.data.getCaseContacts();
            if (this.selectedCaseContact) {
              const recoveredCaseContacts = [this.selectedCaseContact, ...ccSource];
              this.data.setCaseContacts(recoveredCaseContacts);
              this.changeOriginCheck.emit("caseRoles");

            }

            else {
              console.error('no selected contact');
            }
          }
        });
      } else {
        // if contact name has not changed

        if (this.selectedCaseContact === null) {
          console.error('no selected contact');
          return;
        }

        const formRoleTypeId = this.roleForm.get('roleTypeId')?.value || '';

        if (formRoleTypeId === '') {
          console.error('no RoleTypeId from form');
          return;
        }

        if (this.checkForDuplicateRoles({ contactId: this.selectedCaseContact.ContactId, roleTypeId: formRoleTypeId }) !== undefined) {
          this.showExistingWarning = true;
          const activity = newActivity({
            message: 'Kontakt med valgt rolle er allerede lagt til.',
            type: "warning",
            timestamp: new Date()
          })

          this.als.appendActivity(activity)
          return;
        }

        let caseContacts = this.data.getCaseContacts();
        const index = caseContacts.findIndex(cc => cc.Id === this.selectedCaseContact?.Id);
        if (index !== -1) {
          caseContacts[index] = this.setRoleTypeProps(caseContacts[index]);
          const gridData = this.setGridData(this.selectedCaseContact, this.currentRoleType);
          this.caseRoleDs = this.caseRoleDs.filter(cc => cc.Id !== this.selectedCaseContact?.Id);
          this.caseRoleDs = [gridData, ...this.caseRoleDs]

          this.data.setCaseContacts(caseContacts);
          this.changeOriginCheck.emit("caseRoles");

          this.data.getCase();

          this.checkIfMandatoryRolesExist();
        }
      }
    } else {
      this.clearSelection();
    }
  }

  onEditISA(caseContact: ICaseContactBE, contact: IContactExtendedBE) {
    const cc = caseContact;
    cc.ContactId = contact.Id;
    const ccSource = this.data.getCaseContacts();
    console.log("ISA Edit", ccSource);

    const ccToChange = ccSource.findIndex(c => c.RoleTypeId === RoleCode.ISA)
    if (ccToChange !== -1) {
      ccSource.splice(ccToChange, 1, cc)
      this.data.setCaseContacts(ccSource);
      this.changeOriginCheck.emit("caseRoles");

      // this.caseRoleDs = this.caseRoleDs.filter(role => role.Id !== )
    }
  }

  private removeCaseContactFromBE(id: string) {
    let caseContacts = this.data.getCaseContacts();

    let _i = caseContacts.findIndex(cc => cc.Id === id);

    if (_i !== -1) {
      caseContacts[_i].IsDeleted = true;
      this.data.setCaseContacts(caseContacts);
      this.changeOriginCheck.emit("caseRoles");

    }
    return caseContacts;
  }


  public removeCaseContact() {
    if (!this.selectedCaseContact) return;

    const confirmation = confirm(SystemDialogEnum.ROLE_DELETE_CONFIRMATION
      .replace("*full_name*", this.selectedCaseContact.ContactName)
      .replace("*role_name*", this.selectedCaseContact.RoleTypeName));
    if (confirmation) {
      if (this.checkIfMandatory(this.selectedCaseContact.RoleTypeId)) {
        const activity = newActivity({
          message: 'Kan ikke slette en obligatorisk rolle.',
          type: "failure",
          timestamp: new Date()
        })
        this.als.appendActivity(activity)
        return;
      }

      this.removeCaseContactFromBE(this.selectedCaseContact.Id);
      this.caseRoleDs = this.caseRoleDs.filter(cc => cc.IsDeleted !== true);
      this.resetState();
      this.checkIfMandatoryRolesExist();
    } else {
      this.clearSelection()
    }

  }

  setGridData(caseContact: ICaseContactBE, roleType: IRoleTypeBE | null, searchedContact?: IContactExtendedBE) {
    let updatedGridContact = {
      ...caseContact,
      RoleTypeCode: roleType!.Code,
      RoleTypeName: roleType!.Name,
      RoleTypeId: roleType!.Id
    };

    if (searchedContact) {
      updatedGridContact = {
        ...updatedGridContact,
        ContactNumber: searchedContact.Number,
        ContactName: searchedContact.Name,
        Address: searchedContact.Address,
        AllPhoneNumbers: searchedContact.AllPhoneNumbers,
        Digipost: searchedContact.Digipost,
        Email: searchedContact.EmailAddress
      };
    }
    return updatedGridContact;
  }

  setCaseContactProps(caseContact: ICaseContactBE, contact: IContactExtendedBE | IContactBE | null, isEditMode: boolean, contactChanged: boolean) {
    caseContact.CaseId = this.data.Id;
    if (contact && contactChanged) {
      caseContact.ContactId = contact.Id;
    }
    caseContact.RoleTypeCategoryId = this.roleForm.get('roleCategoryId')!.value!;
    caseContact.RoleTypeId = this.roleForm.get('roleTypeId')!.value!;
    caseContact.RoleTypeCode = this.roleTypeCode
    caseContact.RoleTypeName = this.roleTypeName

    if (!isEditMode || contactChanged) {
      caseContact.IsNew = true;
    }
    return caseContact;
  }


  public handleRowSelection(contact: ICaseContactBE) {

    this.resetState();
    this.selectedCaseContact = contact;
    this.currentContactId = this.originalContactId = contact.ContactId;
    this.roleTypeCategoryId = this.selectedCaseContact.RoleTypeCategoryId;
    this.roleTypeId = this.selectedCaseContact.RoleTypeId;
    this.roleTypeCode = this.selectedCaseContact.RoleTypeCode;
    this.roleTypeName = this.selectedCaseContact.RoleTypeName;
    this.activeRoleTypes = this.getActiveRoleTypes(this.roleTypeCategoryId)

    this.currentRoleType = this.setCurrentRoleTypeById(this.roleTypeId)
    this.roleForm.patchValue({
      roleCategoryId: this.selectedCaseContact.RoleTypeCategoryId,
      roleTypeId: this.selectedCaseContact.RoleTypeId,
      contactName: `${this.selectedCaseContact.ContactNumber} ${this.selectedCaseContact.ContactName}`,
    });

    this.setSectorId();
  }

  ISAorKHSelected() {
    if (!this.selectedCaseContact) return false;
    return this.selectedCaseContact.RoleTypeId === RoleCode.ISA.toLocaleLowerCase() || this.selectedCaseContact.RoleTypeId === RoleCode.KH.toLocaleLowerCase()
  }

  clearSelection() {
    this.grid.deselectAllRows();
    this.grid.clearCellSelection();
    this.initializeForm();
  }

  resetState() {
    this.selectedCaseContact = null;
    this.searchedContact = null;
    this.roleTypeChanged = false;
    this.contactSearchInput = "";
    this.currentRoleType = null;
    this.roleTypeId = '';
    this.roleTypeCode = '';
    this.roleTypeName = '';
    this.roleTypeCategoryId = this.internCategoryId;
    this.activeRoleTypes = this.getActiveRoleTypes(this.internCategoryId);
    this.showDialog = false;
    this.showExistingWarning = false;
    this.currentContactId = "";
    this.originalContactId = "";
    this.initializeForm();
    this.IsaOrKhSelected = false;
  }

  resetForm() {
    this.roleForm.reset();
  }


  setSectorId() {
    if (this.roleTypeCategoryId === this.internCategoryId) { this.contactSectorId = ContactSectorIdEnum.M } else {
      this.contactSectorId = ContactSectorIdEnum.A
    };
  }

  // onRoleCategoryChanging(id: string) {
  //   this.roleForm.get('roleTypeId')?.patchValue('');
  //   this.roleTypeCategoryId = id;
  //   // this.setSectorId();
  //   this.activeRoleTypes = this.getActiveRoleTypes(id);
  //   this.contactSectorId = this.roleTypeCategoryId.toUpperCase() === this.internCategoryId.toUpperCase() ? ContactSectorIdEnum.M : ContactSectorIdEnum.A;
  //   this.showExistingWarning = false;
  //   this.existingWarningProp = "";
  //   this.activeUsersEnabled = (this.roleTypeCategoryId === this.internCategoryId);
  // }

  onRoleCategoryChanging(id: string) {
    this.roleForm.get('roleTypeId')?.patchValue('');
    this.roleTypeCategoryId = id;
    this.activeRoleTypes = this.getActiveRoleTypes(id);
    this.contactSectorId = this.roleTypeCategoryId.toUpperCase() === this.internCategoryId.toUpperCase() ? ContactSectorIdEnum.M : ContactSectorIdEnum.A;
    this.showExistingWarning = false;
    this.existingWarningProp = "";
    this.activeUsersEnabled = (this.roleTypeCategoryId === this.internCategoryId);
  }




  roleTypeChanged = false;

  // isShowWarning(roleId: string, displayText: string): void {
  //   this.showExistingWarning = false;
  //   this.existingWarningProp = `en ${displayText}` 

  //   if (roleId === this.ISAId && this.caseResponsibleExist()) {
  //     this.showExistingWarning = true;
  //   } else if (roleId === this.KHAId && this.KlientHAdresstExist()) {
  //     this.showExistingWarning = true;
  //   } else {
  //     this.showExistingWarning = false;
  //   }
  //   if (!this.canAddNewRole(roleId)) {
  //     this.showExistingWarning = true;
  //     return;
  //   }

  // }


  checkRoleExistWarning(roleId: string, displayText: string): void {
    const isCaseResponsibleExist = roleId === this.ISAId && this.caseResponsibleExist();
    const isMainClientAddressExist = roleId === this.KHAId && this.KlientHAdresstExist();

    this.existingWarningProp = `en ${displayText}`;
    this.showExistingWarning = !this.canAddNewRole(roleId) || isCaseResponsibleExist || isMainClientAddressExist;
  }

  onRoleTypeChanging(e: ISimpleComboSelectionChangingEventArgs) {

    this.roleTypeChanged = true;
    this.showExistingWarning = false;
    this.existingWarningProp = "";

    const { newValue, newSelection }: { newValue: string, newSelection: IRoleTypeBE } = e;
    const id = newSelection.Id

    this.checkRoleExistWarning(id, e.displayText);

    if (newValue === undefined) {
      e.cancel = true;
      return
    }
    this.roleTypeChanged = true;

    this.currentRoleType = this.setCurrentRoleTypeById(id)

  }

  setCurrentRoleTypeById(id: string): IRoleTypeBE | null {
    const index = this.activeRoleTypes.findIndex(rc => rc.Id === id);

    if (index !== -1) {

      return this.activeRoleTypes[index];
    } else {
      console.warn(`RoleType not found.`);
      return null;
    }
  }

  roleDelete() {

  }
  onTelClick(ev: MouseEvent, data: string) {
    ev.stopImmediatePropagation();
    document.location.href = `tel:${data.replace(/\D+/g, '')}`;
  }

  onMailClick(ev: MouseEvent, data: string) {
    ev.stopImmediatePropagation();
    document.location.href = `mailto:${data}`;
  }

  onGPSClick(ev: MouseEvent, data: string) {
    ev.stopImmediatePropagation();
    let adr: string;
    if (data.includes(",")) {
      adr = data.split(",")[0];
    } else {
      adr = data;
    }

    if (adr) {
      window.open(`http://maps.google.com/?q=${adr}`, '_blank');
    }
  }

  queryParams: any;

  // onRoleChangeAccepted() { this.onEdit(); this.roleChangeWarning.close(); }
  onSearchDialogClosed() { }

  onRowDoubleClick({ cell }: IGridCellEventArgs) {
    if (this.contactRights === 'NONE') {

      const activity = newActivity({
        message: 'Ingen tilgang på området "Kontakter"',
        type: "failure",
        timestamp: new Date()
      })

      this.als.appendActivity(activity)
      return
    }
    const selected = cell.row.data as ICaseContactBE;
    this.router.navigate(['contact', selected.ContactId])
  }

  onContactOpen() {
    if (this.contactRights === 'NONE') {

      const activity = newActivity({
        message: 'Ingen tilgang på området "Kontakter"',
        type: "failure",
        timestamp: new Date()
      })

      this.als.appendActivity(activity)
      return
    }
    if (this.selectedCaseContact) {
      this.router.navigate(['contact', this.selectedCaseContact.ContactId])
    }
  }


  KlientHAdresstExist(): boolean {
    return this.caseRoleDs.some(role => role.RoleTypeId === RoleCode.KH.toLocaleLowerCase());
  }

  caseResponsibleExist(): boolean {
    return this.caseRoleDs.some(role => role.RoleTypeId === RoleCode.ISA.toLocaleLowerCase());
  }


  isItemDisabled(itemId: string): boolean {
    return (itemId === this.ISAId && this.caseResponsibleExist()) ||
      (itemId === this.KHAId && this.KlientHAdresstExist());
  }

  checkIfFormChanged() {
    const roleTypeChanged = this.selectedCaseContact?.RoleTypeId !== this.roleForm.get('roleTypeId')?.value;
    const contactChanged = this.originalContactId !== this.currentContactId;
    return !!(roleTypeChanged || contactChanged)
  }

  // submitEnabled(): boolean {
  //   const roleCategoryId = this.roleForm.get('roleCategoryId')?.value;
  //   const roleTypeId = this.roleForm.get('roleTypeId')?.value;
  //   const contactName = this.roleForm.get('contactName')?.value;
  //   const contact = this.roleForm.get('contact')?.value;

  //   return !!roleCategoryId && !!roleTypeId && !!contactName &&
  //     !!(this.selectedCaseContact || this.searchedContact || contact) &&
  //     !!this.checkIfFormChanged() && !this.showExistingWarning;
  // }

  submitEnabled(): boolean {
    const roleCategoryId = this.roleForm.get('roleCategoryId')?.value;
    const roleTypeId = this.roleForm.get('roleTypeId')?.value;
    const contactName = this.roleForm.get('contactName')?.value;
    const contact = this.roleForm.get('contact')?.value;

    return this.getRolePermissions() > 0 && !!roleCategoryId && !!roleTypeId && !!contactName &&
      !!(this.selectedCaseContact || this.searchedContact || contact) &&
      !!this.checkIfFormChanged() && !this.showExistingWarning;
  }



  clearSearch() {
    this.roleForm.get('contactName')?.setValue("");
  }

  checkIfMandatoryRolesExist(): boolean {
    const hasKH = this.caseRoleDs.some(role => role.RoleTypeId.toLocaleLowerCase() === RoleCode.KH.toLocaleLowerCase());
    const hasISA = this.caseRoleDs.some(role => role.RoleTypeId.toLocaleLowerCase() === RoleCode.ISA.toLocaleLowerCase());
    const result = hasKH && hasISA;

    this.mandatoryRolesCheck.emit(result);

    console.log("Mandatory roles exist: ", result);

    return result;
  }

  checkIfMandatory(roleId: string): boolean {
    return roleId.toUpperCase() === RoleCode.KH.toUpperCase() || roleId.toUpperCase() === RoleCode.ISA.toUpperCase();
  }
  getUserContact(contactId: string): string | undefined {
    return this.sys.allEmployees.find(employee => employee.ContactId === contactId)?.ContactId;
  }

  checkForDuplicateRoles({ contactId, roleTypeId }: { contactId: string, roleTypeId: string }) {

    return this.data.getCaseContacts().find(role => role.RoleTypeId === roleTypeId && role.ContactId === contactId);
  }

  resetContactDialog() {
    this.showDialog = false;
    this.contactSearchInput = ""
  }
}
