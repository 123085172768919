import { Directive, Input, SimpleChanges } from '@angular/core';
import { IgxGridComponent } from '@infragistics/igniteui-angular';

@Directive({
  selector: '[appGridDownloadProgress]',
  standalone: true
})
export class GridDownloadProgressDirective {

  constructor(private hostComponent: IgxGridComponent) {
    this.progressCurrent = null;
   }
  @Input() public progressCurrent: number | null
  

  
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.insertNode();
    }, 200);
   
  }

  currentChange(changes: SimpleChanges){
    return (changes['progressCurrent'] && changes['progressCurrent'].currentValue !== null)
  }



  ngOnChanges(changes: SimpleChanges){    
    if(this.hostComponent.footer) {
      if(changes){
        if(this.currentChange(changes)){
          this.updateProgressBar(changes['progressCurrent'].currentValue, changes['progressCurrent'].previousValue);
        }
      }
    }
   
  }
  

  insertNode(){
    const parentElement = this.hostComponent.footer.nativeElement.firstChild as HTMLElement;
    
    const wrapper = document.createElement("span");
    wrapper.setAttribute("id", "progress-wrapper");
    wrapper.style.marginLeft = '1rem';

    const seperator = document.createElement("span");
    seperator.innerText = "|";

    const progress = document.createElement("progress")
    progress.setAttribute("id", "download-progress");
    progress.setAttribute("value", "0");
    progress.setAttribute("max", "100");
    progress.style.marginLeft = '1rem';

    if(parentElement.firstChild){
      wrapper.append(seperator, progress);
    } else {
      wrapper.append(progress);
    }
    
    parentElement.insertAdjacentElement("beforeend", wrapper);
  }


  updateProgressBar(current: number, previous?: number){
    const parentElement = this.hostComponent.footer.nativeElement.firstChild.querySelector('#progress-wrapper') as HTMLElement;
    const progressbar = parentElement.querySelector('#download-progress') as HTMLProgressElement
    const progressNumber = parentElement.querySelector('#progress-percent') as HTMLSpanElement
    if(!progressNumber) {
      const progressNumber = document.createElement("span");
      progressNumber.setAttribute("id", "progress-percent");
      progressNumber.style.marginLeft = '1rem';
      progressNumber.innerText = "0%";
      parentElement.append(progressNumber);
    }
    if(progressbar && progressNumber)  {
      progressbar.setAttribute("value", current.toString());      
      progressNumber.innerText = current.toString()+"%";      
    }

    if(current === 0 && previous === 100) {
      progressNumber.remove();
    }
  }

}
