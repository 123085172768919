<!-- <div class="cost-container"> -->
<form class="group-form" [formGroup]="costsForm" (ngSubmit)="onSubmit()">

  <div class="div1 simple-select-wrapper">
    <label igxLabel class="simple-select-label simple-select-label--selected">Utført av</label>
    <igx-simple-combo [formControl]="costsForm.controls.completedBy" class="simple-select-disable-reset"
      [data]="employees" displayDensity="compact" [displayKey]="'ContactName'" [valueKey]="'Id'" [type]="'border'"
      igxComboItem="comboItem" [filteringOptions]="filterOptions" (selectionChanging)="combo.searchTypeChanging($event)"
      (opening)="combo.focusInputAndFixedHeight($event, false)" [disabled]="!hasRightToEditCosts">
      <ng-template igxComboItem let-display let-key="valueKey">
        <div class="selectItem-content-wrapper">
          <span>{{ display.Shortform }}</span>
          <span>{{ display.ContactName }}</span>
        </div>
      </ng-template>
      <ng-template igxComboEmpty>
        <span class="empty-class">Ingen treff</span>
      </ng-template>
    </igx-simple-combo>
  </div>

  <div class="div2 simple-select-wrapper">
    <label igxLabel class="simple-select-label simple-select-label--selected">Type</label>
    <igx-simple-combo [formControl]="costsForm.controls.selectedTypeId" class="simple-select-disable-reset"
      [data]="costTypes" [groupKey]="'mainTypeName'" displayDensity="compact" [displayKey]="'Name'"
      [filteringOptions]="filterOptions" [valueKey]="'Id'" [type]="'border'" igxComboItem="comboItem"
      (selectionChanging)="combo.searchTypeChanging($event)" (opening)="combo.focusInputAndFixedHeight($event)"
      required>
      <ng-template igxComboItem let-display let-key="valueKey">
        <div class="selectItem-content-wrapper">
          <span>{{ display.Code }}</span>
          <span>{{ display.Name }}</span>
        </div>
      </ng-template>
      <ng-template igxComboEmpty>
        <span class="empty-class">Ingen treff</span>
      </ng-template>

      <ng-template igxComboHeaderItem let-display let-key="groupKey">
        <strong class="type-title">{{ display[key] }}</strong>
      </ng-template>
    </igx-simple-combo>
  </div>

  <div class="div3">
    <igx-input-group displayDensity="compact" type="border">
      <textarea igxInput name="description" autocomplete="off" type="text" rows="3"
        style="resize: none;margin-bottom: 18px;" [formControl]="costsForm.controls.description"
        [value]="costsForm.controls.description" required [readOnly]="!hasRightToEditCosts"
        (input)="setShouldUpdateDescription($event.target)"></textarea>
      <label igxLabel for="description">Beskrivelse</label>
    </igx-input-group>
  </div>

  <div class="div4">
    <igx-date-picker #datePicker [formControl]="costsForm.controls.date" [displayDensity]="'compact'"
      inputFormat="dd.MM.yyyy" type="border" todayButtonLabel="I dag" cancelButtonLabel="Avbryt"
      (click)="datePicker.open()">
      <label igxLabel for="date">Dato</label>
      <igx-icon class="date-custom-suffix" igxPrefix (click)="decrementDate()">keyboard_arrow_left</igx-icon>
      <igx-icon igxSuffix (click)="datePicker.open()">today</igx-icon>
      <igx-icon class="date-custom-suffix" igxSuffix (click)="incrementDate()">keyboard_arrow_right</igx-icon>
    </igx-date-picker>
  </div>

  <div class="div5">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput class="search-input" name="amount" autocomplete="off" type="number"
        [formControl]="costsForm.controls.amount" required [readOnly]="!hasRightToEditCosts" />
      <label igxLabel for="amount">Antall</label>
    </igx-input-group>
  </div>

  <div class="div6">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput class=" search-input" name="price" autocomplete="off" type="number"
        [formControl]="costsForm.controls.rate" [readOnly]="!hasRightToEditCosts" />
      <label igxLabel for="price">Pris</label>
    </igx-input-group>
  </div>

  <div class="div7">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput class=" search-input" name="fee" autocomplete="off" type="number"
        [formControl]="costsForm.controls.fee" [readOnly]="!hasRightToEditCosts" />
      <label igxLabel for="fee">Beløp {{ !!costsForm.value.selectedTypeId ? costsForm.value.isVariable ?
        "= antall x pris": "" : ""}}</label>
    </igx-input-group>
  </div>
  @if(selectionIsInvoiced === false) {
  <div class="div8 simple-select-wrapper">
    <label igxLabel class="simple-select-label simple-select-label--selected">Fakturakode</label>
    <igx-simple-combo [formControl]="costsForm.controls.invoiceTypeId" class="simple-select-disable-reset"
      [data]="invoiceTypes" displayDensity="compact" [displayKey]="'Name'" [valueKey]="'Id'" [type]="'border'"
      igxComboItem="comboItem" [filteringOptions]="filterOptions" (selectionChanging)="combo.searchTypeChanging($event)"
      (opening)="combo.focusInputAndFixedHeight($event, false)">
      <ng-template igxComboItem let-display let-key="valueKey">
        <div class="selectItem-content-wrapper">
          <span>{{ display.Code }}</span>
          <span>{{ display.Name }}</span>
        </div>
      </ng-template>
      <ng-template igxComboEmpty>
        <span class="empty-class">Ingen treff</span>
      </ng-template>
    </igx-simple-combo>
  </div>
  } @else {
  <div class="div8">
    <igx-input-group displayDensity="compact" type="border">
      <input [disabled]="true" igxInput class="search-input" name="invoiceCode" value="Fakturert" autocomplete="off"
        type="text" />
      <label igxLabel for="invoiceCode">Fakturakode</label>
    </igx-input-group>
  </div>
  }

  <div class="buttons-container">
    @if(selectionIsInvoiced === false) {
    <div class="div9">
      <button [ngStyle]="{'background-color': (saveButtonActive) && hasRightToEditCosts ? '#AEC965' : ''}" type="submit"
        igxButton="raised" igxButtonColor="white" igxRipple="white"
        [disabled]="!saveButtonActive || !hasRightToEditCosts">
        Registrer
      </button>
    </div>
    <div class="div10">
      <button [ngStyle]="{'background-color': (deleteButtonActive) && hasRightToEditCosts ? '#F52525' : ''}"
        type="button" igxButton="raised" igxButtonColor="white" igxRipple="white"
        [disabled]="!deleteButtonActive || !hasRightToEditCosts " (click)="deleteCostsRegistration();">
        Slett
      </button>
    </div>
    <div class="div11">
      <button type="button" igxButton="raised" igxButtonColor="white" igxRipple="white"
        [disabled]="!cancelButtonActive  " (click)="resetCostsform();">
        Avbryt
      </button>
    </div>
    } @else {
    <div class="div9">
      <button type="button" igxButton="raised" igxButtonColor="white" igxRipple="white" (click)="resetCostsform();">
        Avbryt
      </button>
    </div>
    }

  </div>
</form>
<!-- </div> -->

<igx-grid #grid igxPreventDocumentScroll #costRegistrationsGrid class="grid-min-size" [data]="costsRegistrations"
  [displayDensity]="deviceService.gridDensity" [emptyGridMessage]="emptyGridMessage"
  emptyFilteredGridMessage="Filtrering gir ingen treff" [autoGenerate]="false" width="100%" height="100%"
  [cellSelection]="'none'" [rowSelection]="'single'" (rowSelectionChanging)="handleRowSelection($event)"
  (contextmenu)="onRightClick($event)" [hideRowSelectors]="true" [allowFiltering]="true">
  <igx-column *ngIf="isDesktop" width="90px" field="CompletedDate" header="Dato" [sortable]="true" [dataType]="'date'"
    [filterCellTemplate]="defaultFilterTemplate" [pipeArgs]="formatDateOptions"></igx-column>
  <igx-column *ngIf="!isMobile" width="120px" field="CompletedByName" header="Utført av" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column *ngIf="isDesktop" width="70px" field="CostRegistrationTypeName" header="Kode" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column [width]=" isMobile ? '40%' : '150px'" field="CostRegistrationTypeCode" header="Type" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column *ngIf="!isMobile" field="Description" header="Beskrivelse" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column [width]=" isMobile ? '20%' : '70px'" field="Number" header="Antall" [sortable]="true"
    [dataType]="'number'" [filterCellTemplate]="defaultFilterTemplate" [hasSummary]="true"
    [summaries]="amountSummary"></igx-column>

  <igx-column [width]=" isMobile ? '20%' : '90px'" field="Rate" header="Pris" [sortable]="true" [dataType]="'currency'"
    [filterCellTemplate]="defaultFilterTemplate">
    <ng-template igxCell let-cell="cell">
      <span>{{ (cell.value === null || cell.value === 0) ? '0,00' : (cell.value | norwegianNumberFormat) }}</span>
    </ng-template>
  </igx-column>
  <igx-column [width]=" isMobile ? '20%' : '120px'" field="Fee" header="Beløp" [sortable]="true" [dataType]="'currency'"
    [filterCellTemplate]="defaultFilterTemplate" [hasSummary]="true" [summaries]="sumSummary">
    <ng-template igxCell let-cell="cell">
      <span>{{cell.value | norwegianNumberFormat}}</span>
    </ng-template>
  </igx-column>
  <igx-column *ngIf="!isMobile" width="140px" field="InvoiceTypeDescription" header="Fakturakode" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
</igx-grid>
<div class="grid-footer-container">
  <dlx-grid-footer-collection>
    <dlx-grid-item-count [isMobile]="isMobile" [current]="grid.totalRowsCountAfterFilter"
      [total]="costsRegistrations ? costsRegistrations.length : 0" />
  </dlx-grid-footer-collection>
  @if(isDesktop){
  <dlx-grid-footer-collection>
    <div class="billable-costs">
      <input type="checkbox" name="billable-costs" id="billable-costs" [(ngModel)]="showBillableCosts"
        (change)="getCostRegistrations()">
      <label for="billable-costs">Vis fakturerbare omkostninger</label>
    </div>
    <div class="checkbox non-billable-costs">
      <input type="checkbox" name="non-billable-costs" id="non-billable-costs" [(ngModel)]="showNonBillableCosts"
        (change)="getCostRegistrations()">
      <label for="non-billable-costs">Vis ikke-fakturerbare omkostninger </label>
    </div>
    <div class="checkbox invoiced-costs">
      <input type="checkbox" name="invoiced-costs" id="invoiced-costs" [(ngModel)]="showInvoicedCosts"
        (change)="getCostRegistrations()">
      <label for="invoiced-costs">Vis fakturerte omkostninger </label>
    </div>
  </dlx-grid-footer-collection>
  }

</div>



<ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
  <grid-filter-input [grid]="costRegistrationsGrid" [column]="column"></grid-filter-input>
</ng-template>


<!-- <igx-dialog #roleDialog title="Bekreftelse" leftButtonLabel="Avbryt" (leftButtonSelect)="roleDialog.close()"
  rightButtonLabel="Ja" (rightButtonSelect)="onAddRoleOKSelected()" message="Valgt medarbeider har ikke rolle på saken. Vil du opprette en rolle for valgt medarbeider?
  Hvis du ikke velger å opprette en rolle for medarbeideren vil ikke timen bli registrert!">
</igx-dialog> -->


<igx-dialog #roleDialog title="Valgt medarbeider har ikke rolle på saken.">
  <div class="flex-col flex-gap-1">
    @if(rolePermission > 0 ){
    <p>Vil du opprette en rolle for valgt medarbeider?</p>
    <!-- <p>Hvis du ikke velger å opprette en rolle for medarbeideren vil ikke omkostningen bli registrert!</p> -->
    } @else {
    <p>Det kreves minst "SE" rettigheter på området "Kontakt" for å legge til rolle.</p>
    <p>Kontakt brukeradministrator for hjelp.</p>

    }

  </div>
  <div igxDialogActions>
    @if(rolePermission > 0){
    <button igxButton (click)="roleDialog.close()" igxButton="flat" igxRipple="white">Avbryt</button>
    }
    <button igxButton (click)="rolePermission >  0 ? onAddRoleOKSelected() : roleDialog.close()" igxButton="raised"
      igxButtonColor="white" igxRipple="white">{{rolePermission > 0 ? 'Ja':
      'Ok'}}</button>
  </div>

</igx-dialog>