import { IDocumentBE } from "@datalex-software-as/datalex-client";

export function newDoc(): IDocumentBE {
  return {
      IsDeleted: false,
      IsNew: true,
      AccountsId: null,
      AddedById: null,
      Author: "",
      AuthorId: null,
      BankruptcyClaimId: null,
      BankruptcyEstateId: null,
      CaseId: null,
      ContactId: null,
      Date: `/Date(${+new Date()}+0200)/`,
      DateChanged: `/Date(${+new Date()}+0200)/`,
      Description: "",
      DocumentCategoryId: null,
      DocumentNr: null,
      DocumentSubCategoryId: null,
      DocumentTemplateId: null,
      DocumentTypeId: "00000000-0000-0000-0000-000000000000",
      DocumentTypeName: "",
      EntryId: "",
      File: {
          IsDeleted: false,
          IsNew: true,
          ChangedDate: "/Date(-62135514000000+0100)/",
          Extension: "",
          FileData: null,
          FileSize: null,
          Filename: "",
          Id: "00000000-0000-0000-0000-000000000000",
          Timestamp: null
      },
      FileId: null,
      FinancialYear: null,
      HasAttachment: null,
      Id: "00000000-0000-0000-0000-000000000000",
      IndexId: null,
      IsOpen: false,
      IsOut: false,
      OriginCode: "",
      Recipient: "",
      RecipientId: null,
      Timestamp: null,
      Title: "",
      Type: "",
      VoucherNumber: null,
      VoucherSeriesId: null
  }
}
