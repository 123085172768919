import { Component, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { CaseBE } from 'src/app/classes/CaseBE';
import { DatalexClient, ICaseContactBE } from '@datalex-software-as/datalex-client';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { toLocalIsoString } from 'src/app/util/DatalexDateTime';
import { RoleCode } from 'src/app/util/RoleTypeEnum';
import { FormsModule } from '@angular/forms';
import { IgxInputGroupComponent, IgxInputDirective, IgxLabelDirective, IgxDatePickerComponent } from '@infragistics/igniteui-angular';
import { HeadingComponent } from '../../../UI/heading/heading.component';
import { NgIf } from '@angular/common';
import { DeviceService } from 'src/app/services/device.service';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';

export interface _ICaseBE extends CaseBE {
  _displaySdate?: string
  _displayCdate?: string
}


@Component({
  selector: 'app-selected-case',
  templateUrl: './selected-case.component.html',
  styleUrls: ['./selected-case.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'nb' }],
  standalone: true,
  imports: [NgIf, HeadingComponent, IgxInputGroupComponent, FormsModule, IgxInputDirective, IgxLabelDirective, IgxDatePickerComponent]
})
export class SelectedCaseComponent implements OnInit {

  @Input() data!: CaseBE;
  @Input() title!: string | null;

  // Viewing variables
  caseClient!: string;
  caseResponsible!: string;
  caseNumber!: number;
  caseRef!: string;
  caseIncDate!: string | null;
  caseMoneyLaunderingRisk!: string | null;
  caseTitle!: string;
  caseDescription!: string;
  caseAccountName!: string;
  caseStatus!: string;
  caseClosedDate!: string | null;
  caseArchiveNumber!: number | null;
  isDesktopDevice!: boolean;
  caseStatusList: { id: number, label: string }[] = [{ id: 0, label: "Aktiv" }, { id: 1, label: "Avsluttet" }, { id: 2, label: "Sperret" }]
  toLocalIsoString = toLocalIsoString;

  hasCaseManagementAccess: boolean = false;

  constructor(public screen: ScreenDimensionService, public deviceService: DeviceService, private rights: UserRightsProviderService) {
    this.hasCaseManagementAccess = this.rights.checkCaseManagementAccess()
  }

  ngOnInit(): void {
    this.initializeComponent();
    this.isDesktopDevice = this.deviceService.getDeviceType() === 'Desktop';

  }

  initializeComponent() {
    this.caseClient = this.findRole(this.data.getCaseContacts(), RoleCode.KH)!.ContactName;
    this.caseResponsible = this.findRole(this.data.getCaseContacts(), RoleCode.ISA)!.ContactName;
    this.caseNumber = this.data.getNumber();
    this.caseRef = this.data.getReference();
    this.caseIncDate = this.data.getStartedDate() !== null ? new Date(this.data.getStartedDate()!).toISOString().substring(0, 10) : null; //yyyy-MM-dd
    this.caseMoneyLaunderingRisk = this.setMoneyLaunderingRisk();
    this.caseTitle = this.data.getTitle();
    this.caseDescription = this.data.getDescription();
    this.caseAccountName = this.data.getAccountName();
    this.caseStatus = this.data.getCaseStatusTypeDescription();
    this.caseClosedDate = this.data.getClosedDate() !== null ? new Date(this.data.getClosedDate()!).toISOString().substring(0, 10) : null; //yyyy-MM-dd
    this.caseArchiveNumber = this.data.getArchiveNumber();

  }


  findRole(contacts: ICaseContactBE[], id: string) {
    return contacts.find((contact) => contact.RoleTypeId === id)
  }

  setMoneyLaunderingRisk() {
    if (this.data.getCaseContacts() === null) return null;

    let risk: number | null = null;

    this.data.getCaseContacts().forEach(contact => {
      if (contact.MoneyLaunderingRiskType != null && (risk == null || contact.MoneyLaunderingRiskType > risk)) {
        risk = contact.MoneyLaunderingRiskType;
      }
    })


    if (risk !== null) {
      switch (risk) {
        case 0:
          return "Ikke v.";
        case 1:
          return "Lav";
        case 2:
          return "Medium";
        case 3:
          return "Høy";
        case 4:
          return "Ikke rel.";
      }
    }
    else {
      if (new Date(this.data.getStartedDate()).getFullYear() >= 2018) {
        return "Ikke v.";
      }
      else {
        return null;
      }
    }

    return null;
  }


  log(e: any) {

  }



}
