import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaseBE } from 'src/app/classes/CaseBE';
import { DatalexClient, ICaseBE, ICaseKeyFiguresBE } from '@datalex-software-as/datalex-client';
import { DatalexExceptionHandlerService } from 'src/app/services/datalex-exception-handler.service';
import { formatter } from 'src/app/util/NumberFormatter';
import { CaseDataService } from '../case-data.service';
import { NorwegianNumberFormatPipe } from '../../../../util/pipes/norwegian-number-format.pipe';
import { LabelInputComponent } from '../../../UI/controls/label-input/label-input.component';
import { HeadingComponent } from '../../../UI/heading/heading.component';
import { NgIf } from '@angular/common';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';

@Component({
  selector: 'app-key-fin-figures',
  templateUrl: './key-fin-figures.component.html',
  styleUrls: ['./key-fin-figures.component.scss'],
  standalone: true,
  imports: [NgIf, HeadingComponent, LabelInputComponent, NorwegianNumberFormatPipe]
})
export class KeyFinFiguresComponent implements OnInit {

  caseId!: string | null;
  keyFigures!: ICaseKeyFiguresBE;

  @Input() data!: CaseBE;
  @Input() title!: string | null;
  hasCaseManagementAccess: boolean = false;

  public formatter = formatter;
  constructor(private route: ActivatedRoute, private dlxEx: DatalexExceptionHandlerService, private dlxClient: DatalexClient, private rights: UserRightsProviderService) {
    this.hasCaseManagementAccess = this.rights.checkCaseManagementAccess()
  }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.paramMap.get('id');
    if (this.caseId) {
      this.dlxClient.GetCaseFinanceNumbers(this.caseId).subscribe({
        next: keyFigures => {
          this.keyFigures = keyFigures;
        },
        error: this.dlxEx.exception.bind(this)
      })
    }
  }

}
