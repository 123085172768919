import { Directive, HostListener, Input, ElementRef, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { IgxGridComponent } from '@infragistics/igniteui-angular';
import { GridMethodsService } from '../services/grid-methods.service';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[appClearGridFilters]',
    standalone: true
})
export class ClearGridFiltersDirective implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @Input('appClearGridFilters') grid!: IgxGridComponent;

  constructor(private gms: GridMethodsService, private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    this.monitorGridFilters();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  monitorGridFilters() {
    const sub = this.grid.filteringExpressionsTreeChange.subscribe(() => {
      const totalFilters = this.grid.filteringExpressionsTree.filteringOperands.length;
      if (totalFilters > 0) {
        this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
      } else {
        this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
      }
    });
    this.subscription.add(sub);
  }

  @HostListener('click')
  onClick() {
    if (this.grid) {
      this.gms.clearAllFilters(this.grid);
    }
  }
}


/* use in a button like this
<button  [appClearGridFilters]="contactSearchGrid">nullstille filter</button>
*/