import { Injectable } from '@angular/core';
import { UserRightsProviderService } from './user-rights-provider.service';
import { UserAreaEnum, UserRightTypeEnum } from '@datalex-software-as/datalex-client';

@Injectable({
  providedIn: 'root'
})
export class DocumentUrlService {

  constructor(private userRights: UserRightsProviderService) { }



  createReadOnlyUrl(url: string) {
    const readOnlyUrl = url.replace('action=default', 'action=view').replace('action=edit', 'action=view');
    return readOnlyUrl;
  }

  getAccessOrientetUrl(url: string) {
    let accessLevel = this.userRights.checkAccess(UserAreaEnum.CASE_DOCUMENTS).accessId;
    //Hvis rettighetene ikke har lastet enda, returner en tom uri
    if (accessLevel === UserRightTypeEnum.NOT_LOADED) return "";

    let isLook = accessLevel === UserRightTypeEnum.LOOK;

    return isLook ? this.createReadOnlyUrl(url) : url;
  }

}
