import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { DatalexClient, IContactExtendedBE, IDocumentBE, IDocumentCategoryBE, IDocumentSubCategoryBE, IDocumentTypeBE, IFileBE, IUserLimitedBE } from '@datalex-software-as/datalex-client';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { IDocumentLimitedGrid } from '../document-grid/document-grid.component';
import { firstValueFrom } from 'rxjs';
import { ContactSectorIdEnum } from 'src/app/util/ContactSectorUtil';
import { IGridCellEventArgs, IgxOverlayService, IgxInputGroupComponent, IgxInputDirective, IgxLabelDirective, IgxIconComponent, IgxSuffixDirective, IgxGridComponent, IgxFilterCellTemplateDirective, IgxColumnComponent, IgxSimpleComboComponent, IgxDateTimeEditorDirective, IgxButtonDirective, IgxRippleDirective } from '@infragistics/igniteui-angular';
import { EventEmitterService, IForcedEventTypeEnum } from 'src/app/services/event-emitter.service';
import { DlxCheckboxComponent } from '../../../../UI/dlx-checkbox/dlx-checkbox.component';
import { GridFilterInputComponent } from '../../../../UI/grid-filter-input/grid-filter-input.component';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { DeviceService } from 'src/app/services/device.service';
import { StaticDocumentTypes } from 'src/app/util/DocumentTypesUtil';


@Component({
  selector: 'app-document-detail-editor',
  templateUrl: './document-detail-editor.component.html',
  styleUrls: ['./document-detail-editor.component.scss'],
  standalone: true,
  imports: [NgIf, IgxInputGroupComponent, FormsModule, IgxInputDirective, IgxLabelDirective, IgxIconComponent, IgxSuffixDirective, IgxGridComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent, IgxColumnComponent, IgxSimpleComboComponent, IgxDateTimeEditorDirective, DlxCheckboxComponent, IgxButtonDirective, IgxRippleDirective]
})
export class DocumentDetailEditorComponent implements OnInit, AfterViewInit {

  @Input() data!: IDocumentLimitedGrid | null;
  @Input() subCategories!: IDocumentSubCategoryBE[] | null;
  @Input() rowId!: number;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() openFileEvent: EventEmitter<any> = new EventEmitter();

  @Input() gotDocumentFullPermissions!: boolean;



  source: IDocumentBE | null = null;
  filebe!: IFileBE;

  documentTypes: IDocumentTypeBE[] = [];
  users: IUserLimitedBE[] = [];
  allUsers: IUserLimitedBE[] = [];
  documentCategories: IDocumentCategoryBE[] = [];

  title!: string | null;
  author!: string;
  authorName!: string;
  recipient!: string | null;
  recipientId!: string | null;
  date!: string | null;
  dateAsNumber!: number;
  dateChanged!: string | null;
  categoryId!: string | null;
  DocumentCategoryName!: string | null;

  subCategoryId!: string | null;
  DocumentSubCategoryName!: string | null;

  documentTypeId!: string;
  outgoing!: boolean | null;
  filename!: string | null;
  extension!: string | null;
  description!: string | null;


  datasource: IContactExtendedBE[] = [];
  recipientName!: string | null;
  noResult: boolean = false;
  showContactSearch = false;

  isDocumentOfStaticType: boolean = false;

  fileExists = false;
  documentBE!: IDocumentBE;

  saving: boolean = false;

  searched: boolean = false;
  noResultsMessage: string = "Søket ga ingen treff.";
  isLoading: boolean = false;

  constructor(
    private sys: SystemCacheService,
    private dlxClient: DatalexClient,
    private change: ChangeDetectorRef,
    public combo: ComboSelectFunctionsService,
    public elRef2: ElementRef,
    private ees: EventEmitterService,
    public deviceService: DeviceService,
    private cdRef: ChangeDetectorRef,
    @Inject(IgxOverlayService) public overlayService: IgxOverlayService,
  ) {

  }

  ngOnInit(): void {

    this.initilizeComponentData();
  }

  ngAfterViewInit(): void {
    this.change.detectChanges();
    this.initializeSubCategories();
  }

  ngOnDestroy() {
    this.data = null;
  }

  private initializeSubCategories(): void {

    if (this.source && this.source.DocumentCategoryId !== null) {
      if (this.source.DocumentCategoryId === undefined) return;

      this.fetchSubCategories(this.source.DocumentCategoryId);
    }
  }

  private fetchSubCategories(categoryId: string): void {
    if (!categoryId) return
    this.dlxClient.GetDocumentSubCategorysByCategory(categoryId).subscribe({
      next: response => {
        this.subCategories = response;
        this.change.detectChanges();
      },
      error: error => console.error('Error fetching subcategories:', error)
    });
  }

  private async initilizeComponentData() {
    this.users = this.sys.allEmployees;
    this.allUsers = this.sys.allUsers;
    this.isDocumentOfStaticType = this.setIsDocumentOfStaticType();
    if (this.isDocumentOfStaticType === false) {
      this.documentTypes = this.setDocumentTypes();
    }

    this.documentCategories = this.sys.documentCategories;
    if (!this.data) return
    this.source = await firstValueFrom(this.dlxClient.GetDocument(this.data.Id));
    this.setComponentData(this.source);

    this.cdRef.detectChanges();

  }

  setDocumentTypes() {
    return this.sys.documentTypes.filter(i => !StaticDocumentTypes.includes(i.Id.toUpperCase()));
  }

  setIsDocumentOfStaticType() {
    if (!this.data) return false;
    return StaticDocumentTypes.includes(this.data?.DocumentTypeId.toUpperCase());
  }

  private setComponentData(source: IDocumentBE | null): void {
    if (source) {
      this.documentBE = source as IDocumentBE;

      if (source.AuthorId !== null) {
        let foundAuthor = this.users.find(i => i.ContactId.toLowerCase() === (source.AuthorId?.toLowerCase() || ''));
        if (!foundAuthor) {
          foundAuthor = this.allUsers.filter(i => i.ContactId.toLowerCase() === (source.AuthorId?.toLowerCase() || ''))[0];
          if (foundAuthor) {
            this.users = [foundAuthor, ...this.users];
          }
        }
      }

      this.title = this.data?.DocumentName || null;
      (this.author as string | null) = source.AuthorId || null;
      this.recipient = this.data?.RecipientName || null;
      this.recipientName = this.data?.RecipientName || null;
      // this.recipientName = source.Recipient ? source.Recipient : null
      this.date = source.Date || null;
      this.dateAsNumber = +new Date(source.Date as string);
      this.dateChanged = source.DateChanged || null;
      this.categoryId = source.DocumentCategoryId || null;
      this.subCategoryId = source.DocumentSubCategoryId || null;
      (this.documentTypeId as string | null) = source.DocumentTypeId || null;
      this.outgoing = source.IsOut || false;
      this.description = source.Description || null;
      if (source.FileId) {
        this.fileExists = true;
        this.dlxClient.GetFileWithoutFileData(source.FileId).subscribe((response) => {
          this.filebe = response;
          this.filename = this.setFileName(response.Filename);
          this.extension = response.Extension;
        })
      }
    }

  }

  setFileName(event: string) {
    const parts = event.split('.');
    parts.pop();
    return parts.join(".");
  }

  categoryChange(event: string): void {
    this.subCategoryId = "";
    this.fetchSubCategories(event);
    this.categoryId = event;
  }

  subCategoryChange(id: string | null): void {
    if (this.source) {
      this.subCategoryId = id;

    }
  }

  setAuthorName(event: string): void {

    const foundAuthor = this.users.find(i => i.ContactId.toLowerCase() === event.toLowerCase());


    if (!foundAuthor) {
      throw new Error("Author not found");
    }
    this.authorName = foundAuthor.ContactName;
  }


  // setAuthorName(event: string): string | null | undefined {

  //   const foundAuthor = this.users.find(i => i.ContactId === event);




  //   return foundAuthor ? foundAuthor.ContactName : this.data?.AuthorName;
  //   // this.authorName = this.users.find(i => i.ContactId === event)!.ContactName 
  // }


  onSave() {
    this.documentBE.Title = this.title!;
    this.documentBE.AuthorId = this.author ? this.author : null;
    this.documentBE.Recipient = this.recipient ? this.recipient : "";
    this.documentBE.RecipientId = this.recipientId ? this.recipientId : null;
    this.documentBE.Date = this.date;
    this.documentBE.DateChanged = this.dateChanged as string;
    this.documentBE.DocumentCategoryId = this.categoryId ? this.categoryId : null;
    this.documentBE.DocumentSubCategoryId = this.categoryId && this.subCategoryId ? this.subCategoryId : null;
    this.documentBE.DocumentTypeId = this.documentTypeId;
    this.documentBE.DocumentTemplateId = null;
    this.documentBE.IsOut = this.outgoing ? true : false;
    this.documentBE.Description = this.description!;
    this.documentBE.File = this.filebe;

    this.saveDocument();
  }

  private saveDocument(): void {


    if (this.source?.FileId) {
      this.filebe.Filename = this.filename + this.extension!;
      this.documentBE.File = this.filebe;
    }

    this.saving = true;

    this.dlxClient.SaveDocumentWithNoFileDataReturn(this.documentBE).subscribe({
      next: (docBe) => {


        this.updateGrid(docBe)
      },
      error: error => {
        console.error('Error saving document:', error);
      },
      complete: () => { this.closeModal(); this.saving = false; }
    })
  }

  public lookupContact(searchText: string = "", contactSectorId: string | null = null) {

    if (!contactSectorId) contactSectorId = ContactSectorIdEnum.A;
    if (!searchText) {
      this.datasource = [];
      this.showContactSearch = true;
      return
    }
    this.isLoading = true;
    this.searched = true;
    this.dlxClient.SearchContacts(searchText, contactSectorId).subscribe({
      next: (contacts) => {
        this.isLoading = false;
        if (contacts.length === 0) {
          this.noResult = true;

          return
        }

        if (contacts.length === 1) {
          this.recipientId = contacts[0].Id;
          this.recipient = contacts[0].Name;
          this.recipientName = contacts[0].Number + " " + contacts[0].Name;
          this.datasource = [];
          this.noResult = false;
          return
        }


        this.datasource = JSON.parse(JSON.stringify(contacts))
        this.noResult = false;

      },
      error: error => {
        this.isLoading = false;
      }
    })

  }

  constactSelected(event: IGridCellEventArgs) {
    const contact = event.cell.row.data;
    this.recipientId = contact.Id;
    this.recipient = contact.Name;
    this.recipientName = contact.Number + " " + contact.Name;
    this.datasource = [];
    this.showContactSearch = false;
  }

  resetComponentData() {
    this.source = null;
    this.title = null;
    (this.author as string | null) = null;
    this.recipient = null;
    this.recipientId = null;
    this.date = null;
    this.dateChanged = null;
    this.categoryId = null;
    this.subCategoryId = null;
    (this.documentTypeId as string | null) = null;
    this.outgoing = false;
    this.filename = null;
    this.extension = null;
    this.description = null;
  }

  closeModal() {
    this.close.emit();
  }

  updateGrid(docBe: IDocumentBE) {
    if (!this.data) return
    this.setAuthorName(this.author!)
    this.data.DocumentName = docBe.Title!;
    this.data.AuthorName = this.authorName ? this.authorName : null;
    this.data.RecipientName = this.recipient ? this.recipient : null;
    this.data.DocumentCategoryId = docBe.DocumentCategoryId;
    this.data.DocumentSubCategoryId = docBe.DocumentSubCategoryId;
    this.data.DocumentTypeId = docBe.DocumentTypeId;
    this.data.Filename = this.filename!;
    this.data.DocumentTypeName = this.documentTypes.find(i => i.Id === docBe.DocumentTypeId)!.Name;
    this.ees.sendForceUpdate({
      type: IForcedEventTypeEnum.DocumentsUpdated,
      document: this.data,
      rowId: this.rowId
    })
  }

  openFile() {
    if (this.data?.FileId)
      this.openFileEvent.emit();
    // this.closeModal();
    this.closeModal();
  }

  get enableSaveButton() {
    if (this.fileExists) {
      const pattern = /^\s*\S+.*$/;
      return !pattern.test(this.filename!);
    }
    return true;
  }


  // filterSearchResult(e: string) {
  //   if (e === null || e === "") {
  //     this.datasource = JSON.parse(JSON.stringify(this.contactSearchResult));
  //     return
  //   }
  //   this.datasource = this.contactSearchResult.filter(i => i.Name.includes(e) || String(i.Number).includes(e));
  //   if (this.datasource.length === 0) {
  //     this.noResult = true;
  //   } else {
  //     this.noResult = false;
  //   }

  // }

}
