import { Injectable } from '@angular/core';
import { CookieProvider } from '../util/Cookies';
import { SystemCacheService } from './system-cache.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(private cookie: CookieProvider, private sys: SystemCacheService) {
  }

 logUpdate() {
 }

  public IsAuthenticated(): boolean {
    let cookieToken = this.cookie.getToken();
    let cookieUrl = this.cookie.getWebURL();
    let cookieUserId = this.cookie.getUserId();
    let cookieSessionId = this.cookie.getSessionId();

    if(window.location.href.includes("standalone")){
      return true;
    }

    if(this.sys.token !== null && this.sys.webURL && this.sys.userId !== null && this.sys.sessionId !== null) {
      return true
    }
    if(cookieToken !== null && cookieUrl !== null && cookieUserId !== null && cookieSessionId !== null)  {
      this.sys.updateSystemChahce(cookieToken, cookieUserId, cookieUrl, cookieSessionId, this.logUpdate);
      return true
    }
    return false
  }
}